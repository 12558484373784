import axios from "axios";
import { Fragment, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import "./App.scss";

import { useDispatch, useSelector } from "react-redux";
import Hyperlink from "./components/Hyperlink";
import Kadobon from "./components/Kadobon";
import Faciliteiten from "./components/Faciliteiten";
import Logo from "./components/Logo";
import Nav from "./components/Nav";
import Personal from "./components/Personal";
import Search from "./components/Search";
import Welcome from "./components/Welcome";
import Tijdschema from "./components/Tijdschema";
import Contest from "./components/Contest";
import Finish from "./components/Finish";
import Contactperson from "./components/Contactperson";
import Address from "./components/Address";

import { NotificationContainer, NotificationManager } from "react-notifications";
import "react-notifications/lib/notifications.css";
import store from "./store";

import Icon from "@identitybuilding/idb-react-iconlib";
import "@identitybuilding/idb-react-ui-elements/dist/styles/Colors.css";
import { updateCurrentEstablishment, updateLang, updateNav } from "./actions/GeneralAction";
import { OsnInputText, OsnSelect } from "@identitybuilding/idb-react-ui-elements";


function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return () => setValue(value => value + 1); // update state to force render
  // An function that increment 👆🏻 the previous state like here 
  // is better than directly setting `value + 1`
}

function App() {
  const dispatch = useDispatch();
  const current_establishment = useSelector((state) => state.general.current_establishment);
  const lang = useSelector((state) => state.general.lang);
  const is_admin = useSelector((state) => state.general.is_admin);
  const translate = useSelector((state) => state.general.translate);
  const showNav = useSelector((state) => state.general.showNav);
  // const Messages = useSelector((state) => state.messages);
  // const [data, setData] = useState([]);
  let { est, ent } = useParams();
  const history = useHistory();
  const forceUpdate = useForceUpdate();

  const [data, setData] = useState("");
  const [tab, isTab] = useState();
  // const [showNav, setShowNav] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const createNotification = (type, message) => {
    if (type === "info") {
      NotificationManager.info(message);
    } else if (type === "success") {
      NotificationManager.success(message);
    } else if (type === "warning") {
      NotificationManager.warning(message);
    } else if (type === "error") {
      NotificationManager.error(message);
    }
  };

  const setTab = (e, type) => {
    let copy = data
    if (e !== undefined) {
      if ((copy.accept_GDPR && copy.establishment_active && copy.accept_terms) || is_admin) {
        if (type === 'nav') {
          if (e <= copy.available_tabs || is_admin) { // check if the tab is available for the user or if the user is an admin
            isTab(e)
            localStorage.setItem("tab", e);
          }
          else if (e < tab) {
            isTab(e)
            localStorage.setItem("tab", e);
          }
        }
        else {
          isTab(e);
          localStorage.setItem("tab", e);
        }
      }
      else {
        // notification
        !copy.establishment_active ? NotificationManager.error("deze actie is niet mogelijk aangezien uw onderneming op inactief staat.")
          : !copy.accept_GDPR ? NotificationManager.error("deze actie is niet mogelijk aangezien u onze GDPR/AVG voorwaarden niet geaccepteerd heeft.")
            : NotificationManager.error("deze actie is niet mogelijk aangezien u onze Algemene voorwaarden niet geaccepteerd heeft.")
      }
    }
    else {
      if (localStorage.getItem("tab")) {
        isTab(Number(localStorage.getItem("tab")));
      }
      else {
        localStorage.setItem("tab", 0);
        isTab(0);
      }
    }

    if (type !== "nav" && e > copy.available_tabs) {
      copy.available_tabs = e
    }

    setLoaded(false)
    setData(copy)
    setLoaded(true)

    // if (localStorage.getItem("tab")) {
    //   isTab(localStorage.getItem("tab"));
    //   let copy = data
    //   if (copy && Number(localStorage.getItem("tab")) > copy.available_tabs) {
    //  JAconsole.log(localStorage.getItem("tab"))
    //     copy.available_tabs = Number(localStorage.getItem("tab")) + 1
    //     setData(copy)
    //   }
    // } else {
    //   localStorage.setItem("tab", 0);
    //   isTab(0);
    // }
  };

  const fetchData = async () => {
    let token = localStorage.getItem("token");
    // get search params
    const searchParams = new URLSearchParams(window.location.search);
    const order = searchParams.get("order");
    if (!token && order) token = process.env.REACT_APP_DEFAULT_OVERRIDE_TOKEN;
    if (token) {
      const headers = {
        "Content-Type": "application/json",
        // 'Authorization': `Token a0a40d840d5cad7525bf70bb6f4eceda7f0b64f0`,
        Authorization: `Token ${token}`,
      };
      axios.get(`${process.env.REACT_APP_BASE_MEDIA_URL}/ci/get/${est}/?lang=${lang}`, {
        headers: headers,
      })
        .then((res) => {

          if (res.detail === "Invalid token.") {
            history.push(`/${ent}/${est}/login?lang=${lang}`);
          } else {
            // set the data
            if (res.data.enterprise_number !== ent) {
              history.push(`/${res.data.enterprise_number}/${est}/?lang=${lang}`);
              window.location.reload()
            }
            else {
              dispatch(updateCurrentEstablishment(res.data));
              setData(res.data);
              setLoaded(true);
            }
          }

          // if(json.path) history.push(`/${json.path}/`)
        })
        .catch((err) => {
          console.log(err)
          if (err.response.status === 401) {
            history.push(`/${ent}/${est}/login?lang=${lang}`);
            localStorage.setItem('token', '')
          }
          else if (err.response.status === 500) {
            createNotification('error', 'Nog geen CI aangemaakt voor de Establishment')
            setTimeout(() => {
              history.push(`/`);
            }, 4000);

          }
        });
    } else {
      history.push(`/${ent}/${est}/login?lang=${lang}`);
    }
  };

  useEffect(() => {
    fetchData();
    setTab();

  }, []);

  return (
    <div className="App">
      {/* <Icon onClick={() => {dispatch(updateNav(!showNav)), forceUpdate()}} name={showNav ? "" : "MenuFill"} className="menu" /> */}
      {/* <Nav /> */}
      <Fragment>
        <div className="main">
          <OsnSelect
            className="lang-select"
            onChange={(e) => {
              localStorage.setItem("lang", e.name);
              window.location.href = `/${ent}/${est}/?lang=${e.name}`
            }}
            active={lang === 'nl' ? 'nl' : lang === 'fr' ? 'fr' : 'en'}
            options={[
              { id: 0, name: 'nl' },
              { id: 1, name: 'fr' },
              { id: 2, name: 'en' }
            ]}
          />
          <h1>
            {translate('dear')} {data.first_name && data.first_name[0].toUpperCase()}
            {data.first_name && data.first_name.slice(1)}
          </h1>
          <p className="subtitle">
            {translate('ready_to_collaborate')} <strong>'{translate('local_online_visibility')}'</strong>
          </p>


          <div className="main-card">
            <section className="card-container">
              <div className={["card", tab === 0 ? "active" : ""].join(" ")}>
                <h3
                  className="card-title"
                  tabIndex={0}
                  onClick={(e) => {
                    store.dispatch({ type: "changeTab", data: "im gone test", event: e });
                    setTab(0, 'nav');
                  }}
                >
                  01
                </h3>
              </div>
              <div className={["card", tab === 1 ? "active" : ""].join(" ")}>
                <h3
                  className="card-title"
                  tabIndex={1}
                  onClick={(e) => {
                    store.dispatch({ type: "changeTab", data: "im gone test", event: e });
                    setTab(1, 'nav');
                  }}
                >
                  02
                </h3>
              </div>

              <div className={["card", tab === 2 ? "active" : ""].join(" ")}>
                <h3
                  className="card-title"
                  tabIndex={2}
                  onClick={(e) => {
                    store.dispatch({ type: "changeTab", data: "im gone test", event: e });
                    setTab(2, 'nav');
                  }}
                >
                  03
                </h3>
              </div>

              <div className={["card", tab === 3 ? "active" : ""].join(" ")}>
                <h3
                  className="card-title"
                  tabIndex={3}
                  onClick={(e) => {
                    store.dispatch({ type: "changeTab", data: "im gone test", event: e });
                    setTab(3, 'nav');
                  }}
                >
                  04
                </h3>
              </div>

              <div className={["card", tab === 4 ? "active" : ""].join(" ")}>
                <h3
                  className="card-title"
                  tabIndex={4}
                  onClick={(e) => {
                    store.dispatch({ type: "changeTab", data: "im gone test", event: e });
                    setTab(4, 'nav');
                  }}
                >
                  05
                </h3>
              </div>

              <div className={["card", tab === 5 ? "active" : ""].join(" ")}>
                <h3
                  className="card-title"
                  tabIndex={5}
                  onClick={(e) => {
                    store.dispatch({ type: "changeTab", data: "im gone test", event: e });
                    setTab(5, 'nav');
                  }}
                >
                  06
                </h3>
              </div>

              <div className={["card", tab === 6 ? "active" : ""].join(" ")}>
                <h3
                  className="card-title"
                  tabIndex={6}
                  onClick={(e) => {
                    store.dispatch({ type: "changeTab", data: "im gone test", event: e });
                    setTab(6, 'nav');
                  }}
                >
                  07
                </h3>
              </div>

              <div className={["card", tab === 7 ? "active" : ""].join(" ")}>
                <h3
                  className="card-title"
                  tabIndex={7}
                  onClick={(e) => {
                    store.dispatch({ type: "changeTab", data: "im gone test", event: e });
                    setTab(7, 'nav');
                  }}
                >
                  08
                </h3>
              </div>

              <div className={["card", tab === 8 ? "active" : ""].join(" ")}>
                <h3
                  className="card-title"
                  tabIndex={8}
                  onClick={(e) => {
                    store.dispatch({ type: "changeTab", data: "im gone test", event: e });
                    setTab(8, 'nav');
                  }}
                >
                  09
                </h3>
              </div>

              <div className={["card", tab === 9 ? "active" : ""].join(" ")}>
                <h3
                  className="card-title"
                  tabIndex={9}
                  onClick={(e) => {
                    store.dispatch({ type: "changeTab", data: "im gone test", event: e });
                    setTab(9, 'nav');
                  }}
                >
                  10
                </h3>
              </div>

              <div className={["card", tab === 10 ? "active" : ""].join(" ")}>
                <h3
                  className="card-title"
                  tabIndex={10}
                  onClick={(e) => {
                    store.dispatch({ type: "changeTab", data: "im gone test", event: e });
                    setTab(10, 'nav');
                  }}
                >
                  11
                </h3>
              </div>

              <div className={["card", tab === 11 ? "active" : ""].join(" ")}>
                <h3
                  className="card-title"
                  tabIndex={11}
                  onClick={(e) => {
                    store.dispatch({ type: "changeTab", data: "im gone test", event: e });
                    setTab(11, 'nav');
                  }}
                >
                  12
                </h3>
              </div>
            </section>
            {loaded && data != "" ? (
              <div>
                {/* {tab === 0 && <Welcome setTab={setTab} data={data} createNotification={createNotification} />} */}
                {tab === 0 && <Welcome setTab={setTab} data={data} createNotification={createNotification} />}
                {tab === 1 && <Personal setTab={setTab} data={data} createNotification={createNotification} />}
                {tab === 2 && <Search setTab={setTab} createNotification={createNotification} data={data} />}
                {tab === 3 && <Address data={data} setTab={setTab} />}
                {tab === 4 && <Logo data={data} createNotification={createNotification} setTab={setTab} />}
                {tab === 5 && <Hyperlink setTab={setTab} data={data} />}
                {tab === 6 && <Contactperson setTab={setTab} data={data} />}
                {tab === 7 && <Kadobon setTab={setTab} data={data} />}
                {tab === 8 && <Faciliteiten setTab={setTab} data={data} createNotification={createNotification} current_establishment={current_establishment} />}
                {tab === 9 && <Tijdschema setTab={setTab} createNotification={createNotification} data={data} />}
                {tab === 10 && <Contest setTab={setTab} data={data} />}
                {tab === 11 && <Finish setTab={setTab} createNotification={createNotification} data={data} />}
              </div>
            ) : (
              <div className="isLoading">
                <Icon name="Loading" animated />
              </div>
            )}
          </div>
        </div>
        <NotificationContainer />
      </Fragment>
    </div>
  );
}

export default App;
