import { Fragment, useEffect, useRef, useState } from "react";
import "./App.scss";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { updateCurrentEstablishment, updateCorrectOTP, setIsAdmin } from "./actions/GeneralAction";
import plennypoint from "./library/images/plenny-point-2.png";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { OsnInputText, OsnSelect } from "@identitybuilding/idb-react-ui-elements";

import Icon from "@identitybuilding/idb-react-iconlib";

const Login = () => {
  const btwRef = useRef("BE0718.600.051");
  let { est, ent } = useParams();
  const OTPref = useRef();
  const history = useHistory();
  const [establishmentsCount, setEstablishmentsCount] = useState("");
  const [data, setData] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [seePassword, setSeePassword] = useState(false);
  const [error, setError] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [hasAcount, setHasAcount] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const lang = useSelector((state) => state.general.lang);
  const translate = useSelector((state) => state.general.translate);

  const dispatch = useDispatch();

  const getEstablishmentCounts = async () => {
    const url = `${process.env.REACT_APP_BASE_MEDIA_URL}/core/api/count/business/`
    const response = await axios.get(url)
    const totalCount = response.data.count;
    // count is a number - convert count to string and then use . for separating thousands
    const count = totalCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    setEstablishmentsCount(count);
  }

  const getData = async () => {
    axios
      .get(`https://search-osn-management-hkfarflgp5aj2vbhfzvmyycuuy.eu-central-1.es.amazonaws.com/_search?q=number:${est}&size=100`, {
        auth: {
          username: `osn-admin`,
          password: `O15s19n14!+`,
        },
      })
      .then((res) => {
        let establishment = {};

        let data = res.data.hits.hits[0]._source;
        establishment.address = {};
        establishment.address.streetname = data.address[`streetname${lang}`] || data.address[`streetname_nl`] || data.address[`streetname_fr`] || data.address[`streetname_de`] || data.address[`streetname_en`];
        establishment.address.municipality = data.address[`municipality${lang}`] || data.address[`municipality_nl`] || data.address[`municipality_fr`] || data.address[`municipality_de`] || data.address[`municipality_en`];
        establishment.address.municipality = establishment.address.municipality && establishment.address.municipality.replace(" (Stad)", "");
        establishment.address.house_number = data.address.house_number;
        establishment.address.box_number = data.address.box_number;
        establishment.address.postal_code = data.address.postal_code;
        establishment.address.slug = data.address.slug;
        establishment.address.sub_municipality = data.address[`sub_municipality${lang}`] || data.address[`sub_municipality_nl`] || data.address[`sub_municipality_fr`] || data.address[`sub_municipality_de`] || data.address[`sub_municipality_en`];
        establishment.name = data[`name${lang}`] || data[`name_nl`] || data[`name_fr`] || data[`name_de`] || data[`name_en`];
        establishment.logos = data.logolinks;
        establishment.enterprise_id = data.from_enterprise.number;
        establishment.id = data.number;
        establishment.juridical_form = data.from_enterprise[`juridical_form_${lang}`] || data.from_enterprise.juridical_form_nl || data.from_enterprise.juridical_form_fr || data.from_enterprise.juridical_form_de || data.from_enterprise.juridical_form_en;
        establishment.contact = {};

        for (let i = 0; i < data.contactmethodlinks.length; i++) {
          if (data.contactmethodlinks[i].medium === "Fixed") {
            establishment.contact.phone = data.contactmethodlinks[i].value_nl || data.contactmethodlinks[i].value_fr || data.contactmethodlinks[i].value_de || data.contactmethodlinks[i].value_en;
          }
          if (data.contactmethodlinks[i].medium === "Mobile") {
            establishment.contact.mobile = data.contactmethodlinks[i].value_nl || data.contactmethodlinks[i].value_fr || data.contactmethodlinks[i].value_de || data.contactmethodlinks[i].value_en;
          }
          if (data.contactmethodlinks[i].medium === "Fax") {
            establishment.contact.fax = data.contactmethodlinks[i].value_nl || data.contactmethodlinks[i].value_fr || data.contactmethodlinks[i].value_de || data.contactmethodlinks[i].value_en;
          }
        }

        // for (let x = 0; x < data.establishmentactivitylinks.length; x++) {
        //   if (data.establishmentactivitylinks[x].score === 70) {
        //     establishment.mainActivity = data.establishmentactivitylinks[x];
        //   }
        // }

        // if (!establishment.mainActivity) {
        //   for (let y = 0; y < data.from_enterprise.enterpriseactivitylinks.length; y++) {
        //     if (data.from_enterprise.enterpriseactivitylinks[y].score === 70 || data.from_enterprise.enterpriseactivitylinks[y].score === 50) {
        //       establishment.mainActivity = data.from_enterprise.enterpriseactivitylinks[y];
        //     }
        //   }
        // }
        // if (!establishment.mainActivity && data.establishmentactivitylinks.length > 0) {
        //       establishment.mainActivity = establishment.mainActivity = data.establishmentactivitylinks[0]
        // }

        axios.get(`${process.env.REACT_APP_BASE_MEDIA_URL}/geo/api/municipality/${establishment.address.slug}/?lang=${lang}`).then((resp) => {
          establishment.address.has_collaboration = resp.data.municipality.has_collaboration;
          establishment.address.full_name = resp.data.municipality.name
          establishment.convincing_letter = resp.data.municipality.convincing_letter
          axios.get(`${process.env.REACT_APP_BASE_MEDIA_URL}/core/api/business/${data.number}/main_category?lang=${lang}`)
            .then((r) => {
              if (r.data.enterprise_categories.length > 0) {
                establishment.mainActivity = r.data.enterprise_categories[0].category
              }
              else if (r.data.establishment_categories.length > 0) {
                establishment.mainActivity = r.data.establishment_categories[0].category
              }
              // for (let x = 0; x < r.data.establishment_categories.length; x++) {
              // console.log(r.data.establishment_categories[x])
              // if (data.establishmentactivitylinks[x].score === 70) {
              //   establishment.mainActivity = data.establishmentactivitylinks[x];
              // }
              // }
              let cates = r.data.establishment_categories.sort((a, b) => b.score - a.score)
              cates.map((data) => {
                if (data.score === 80 && data.is_main) { establishment.customerCategory = data.category }
                else if (data.score === 80) { establishment.customerCategory = data.category }
              })
              setData(establishment);
              dispatch(updateCurrentEstablishment(establishment));

            })
          // setData(establishment);
          // dispatch(updateCurrentEstablishment(establishment));
        });
      })

      .then(() => {
        setLoaded(true);
      });
  };

  useEffect(() => {
    // fetchData();
    localStorage.setItem("tab", 0);
    getData();
    getEstablishmentCounts();
  }, []);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      // fetch("https://management.100procentlokaal.be/ci/current_user/", {
      //   headers: {
      //     Authorization: `Token ${localStorage.getItem("token")}`,
      //   },
      // })
      //   .then((res) => res.json())
      //   .then((json) => {
      //     if (json.path) history.push(`/${json.path}/?lang=${lang}`);
      //     //   this.setState({ username: json.username });
      //   });
      const headers = { "Content-Type": "application/json", Authorization: `Token ${localStorage.getItem("token")}` };

      axios.get(`${process.env.REACT_APP_BASE_MEDIA_URL}/ci/current_user/`, {
        headers: { Authorization: `Token ${localStorage.getItem("token")}` },
        params: { establishment: est }
      })
        .then((res) => {
          if (res.data.is_superuser === true) {
            history.push(`/${ent}/${est}/?lang=${lang}`);
            dispatch(setIsAdmin(true))
          }
          else if (res.data.path) history.push(`/${res.data.path}/?lang=${lang}`);
        });
    }
  }, []);

  const login = (e) => {
    e.preventDefault();
    if (forgotPassword) {
      if (email) {
        axios
          .post(`${process.env.REACT_APP_BASE_MEDIA_URL}/ci/send_password_reset/`, {
            email: email,
          })
          .then((res) => console.log(res));
      } else {
        setError("Gelieve een E-mailadres mee te geven!");
      }
    } else if (hasAcount) {
      axios.post(`${process.env.REACT_APP_BASE_MEDIA_URL}/ci/login/`, {
        email: email,
        password: password,
      })
        .then((res) => {
          localStorage.setItem("token", res.data.token);
          history.push(`/${ent}/${est}/?lang=${lang}`);
          // if(res.data.status === "Succes") { history.push(`/${ent}/${est}/`) }
        })
        .catch((err) => NotificationManager.error("Geen geldige logingegevens!"));
    } else {
      axios
        .post(`${process.env.REACT_APP_BASE_MEDIA_URL}/ci/validate/${est}/`, {
          OTP: OTPref.current.value,
        })
        .then((res) => {
          if (res.data.valid) {
            dispatch(updateCorrectOTP(true));
            history.push(`/${ent}/${est}/signin?lang=${lang}`);
          } else {
            NotificationManager.error("Jouw OTP is niet geldig!");
          }
        });
    }
    // if (OTPref.current.value === "test") {
    //
    // }
  };


  return (
    <section className="p-login">
      <OsnSelect
        className="lang-select"
        onChange={(e) => {
          localStorage.setItem("lang", e.name);
          window.location.href = `/${ent}/${est}/login?lang=${e.name}`
        }}
        active={lang === 'nl' ? 'nl' : lang === 'fr' ? 'fr' : 'en'}
        options={[
          { id: 0, name: 'nl' },
          { id: 1, name: 'fr' },
          { id: 2, name: 'en' }
        ]}
      />
      {data && loaded && (
        <Fragment>
          <h1 className="p-login__title">
            {data.first_name ? translate('dear') + ' ' + data.first_name && data.first_name[0].toUpperCase() + data.first_name && data.first_name.slice(1)
              : translate('dear') + ' ' + data.name}
          </h1>
          <div className="p-login__bar">
            {lang === 'nl' ? <p>Onderstaande info van jouw onderneming, {data.name} ({data.enterprise_id}), kan misschien geoptimaliseerde informatie nodig hebben?</p>
              : lang === 'fr' ? <p>Les informations ci-dessous de votre entreprise, {data.name} ({data.enterprise_id}), peuvent nécessiter des informations optimisées ?</p>
                : <p>The information below from your company, {data.name} ({data.enterprise_id}), may need optimized information?</p>}
          </div>
          <section>
            <div className="p-login__intro-body">
              <div>
                <div className="text">
                  <h1>{translate('together_we_work')} </h1>
                  {lang === 'nl' ?
                    <p>
                      identityBuilding ontwikkelt www.100procentlokaal.be om de <span className="highlight">lokale economie</span> te <span className="highlight">ondersteunen</span> en extra zuurstof te geven. Samen met jullie bouwen wij
                      <span className="highlight"> “HET&nbsp;STERKSTE&nbsp;NETWERK”</span>
                    </p>
                    : lang === 'fr' ?
                      <p>
                        identityBuilding développe www.100pourcentlocal.be pour <span className="highlight">soutenir l'économie locale</span> et lui donner un supplément d'oxygène. Avec vous, nous construisons
                        <span className="highlight"> “LE&nbsp;RÉSEAU&nbsp;LE&nbsp;PLUS&nbsp;FORT”</span>
                      </p>
                      :
                      <p>
                        identityBuilding develops www.100percentlocal.be to support the <span className="highlight">local economy</span> and to give extra oxygen. Together with you, we build
                        <span className="highlight"> “THE&nbsp;STRONGEST&nbsp;NETWORK”</span>
                      </p>
                  }
                  {lang === 'nl' ?
                    <p>
                      Dit is het grootste en belangrijkste Belgische initiatief waarbij alle ondernemers en haar klanten als doelgroep centraal staan. Verdeeld over <span className="highlight">581 steden en gemeenten</span> kan de gebruiker uit{" "}
                      <span className="highlight" style={{ color: "var(--sub)" }}>
                        {establishmentsCount}{" "}
                      </span>
                      actieve vestigingen zoeken naar elke activiteit.
                    </p>
                    : lang === 'fr' ?
                      <p>
                        Il s'agit de la plus grande et de la plus importante initiative belge dans laquelle tous les entrepreneurs et leurs clients en tant que groupe cible occupent le devant de la scène. Réparti sur <span className="highlight">581 villes et municipalités</span> les utilisateurs peuvent visiter{" "}
                        <span className="highlight" style={{ color: "var(--sub)" }}>
                          {establishmentsCount}{" "}
                        </span>
                        établissements actifs en recherchant n'importe quelle activité.
                      </p>
                      :
                      <p>
                        This is the largest and most important Belgian initiative in which all entrepreneurs and its customers as a target group take centre stage. <span className="highlight">581 cities and municipalities</span> users can visit{" "}
                        <span className="highlight" style={{ color: "var(--sub)" }}>
                          {establishmentsCount}{" "}
                        </span>
                        active establishments by searching for any activity.
                      </p>
                  }

                  {lang === 'nl' ?
                    <p>
                      Een aantal steden en gemeenten ondersteunen dit initiatief en hebben intussen <span className="highlight">hun samenwerking </span> reeds <span className="highlight"> bevestigd</span>.
                      {data.address.has_collaboration ? <span> Het lokaal bestuur van {data.address.full_name} <a className="highlight" style={{ "color": "var(--sub)" }} target="_blank" href={data.convincing_letter}>bevestigde ook de samenwerking.</a></span> : ` Het lokaal bestuur van ${data.address.full_name} bevestigde de samenwerking nog niet, help jij mee om hen te overtuigen?`}
                    </p>
                    : lang === 'fr' ?
                      <p>
                        Un certain nombre de villes et de municipalités soutiennent cette initiative et ont entre-temps déjà <span className="highlight"> confirmé leur coopération.</span>
                        {data.address.has_collaboration ? <span> La gouvernance locale de {data.address.full_name} a également <a className="highlight" style={{ "color": "var(--sub)" }} target="_blank" href={data.convincing_letter}>confirmé sa coopération.</a></span> :
                          ` Le gouvernement local de ${data.address.full_name} n'a pas encore confirmé la collaboration, allez-vous aider à les convaincre ?`}
                      </p>
                      :
                      <p>
                        A number of cities and municipalities support this initiative and have meanwhile already <span className="highlight">confirmed their cooperation. </span>
                        {data.address.has_collaboration ?
                          <span> The local government of {data.address.full_name} <a className="highlight" style={{ "color": "var(--sub)" }} target="_blank" href={data.convincing_letter}>confirmed the cooperation as well.</a></span> :
                          ` The local government of ${data.address.full_name} has not yet confirmed the collaboration, will you help convince them?`}
                      </p>
                  }


                  {lang === 'nl' ?
                    <p>
                      Ontdek de <span className="highlight"> meerwaarde</span> voor jouw onderneming op:
                    </p>
                    : lang === 'fr' ?
                      <p>
                        Découvrez la <span className="highlight"> valeur ajoutée</span> pour votre entreprise sur :
                      </p>
                      :
                      <p>
                        Discover the <span className="highlight"> added value</span> for your business at:
                      </p>
                  }
                  <p>
                    <a className="sub" target="_blank" rel="noreferrer" href={`https://${data.address.slug}.${lang === 'nl' ? '100procentlokaal.be' : '100pourcentlocale.be'}`}>
                      <span>{data.address.slug}</span>
                      {lang === 'nl' ? '.100procentlokaal.be' : '.100pourcentlocale.be'}
                    </a>
                    {/* <span className="highlight"> of</span> <br /> */}
                    <span > {lang === 'nl' ? 'of' : lang === 'fr' ? 'ou' : 'or'}</span> <br />
                    <a className="sub" target="_blank" rel="noreferrer" href={`https://${lang === 'nl' ? 'www.ondernemersnetwerk.be' : 'www.reseauentrepreneurs.be'}/zoeken/in/${data.address.slug}/naar/Pagina=1`}>

                      <span>{data.address.slug}</span>
                      {lang === 'nl' ? '.ondernemersnetwerk.be' : '.reseauentrepreneurs.be'}
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="image-container">
              <div className="flex-container">
                <div className="percent-card">
                  <div className="image-container">
                    {
                      data.logos.length > 0 ?
                        <img alt="logo" src={data.logos[0].png_nl || data.logos[0].png_fr || data.logos[0].png_de || data.logos[0].png_en} />
                        :
                        <img alt="logo" src={require('./library/images/download.png')} />

                    }
                  </div>
                  <p className="name">
                    {data.name} {data.juridical_form}
                  </p>
                  <div className="data-container">
                    <div className="adres">
                      <Icon name="Mapmarker" />
                      <p>
                        {data.address.streetname} {data.address.house_number} <br />
                        {data.address.postal_code} {data.address.sub_municipality} {data.address.municipality && `(${data.address.municipality})`}
                      </p>
                    </div>
                    <span>
                      <Icon name="Phone" />
                      <p>{data.contact.phone}</p>
                    </span>
                    <span>
                      <Icon name="Cellphone" />
                      <p>{data.contact.mobile}</p>
                    </span>
                    <span>
                      <Icon name="Fax" />
                      <p>{data.contact.fax}</p>
                    </span>
                  </div>
                  <div className="contact-button">
                    <span>
                      <Icon name="Website" />
                      {translate('visit_our_site')}
                    </span>
                    <span>
                      {translate('discover_more')} <Icon name="ArrowRight" />
                    </span>
                  </div>
                </div>
                <div>
                  <img alt="plenny next to card" className="plenny" src={plennypoint} />
                  <div className="info-card">
                    <div className="adres-line">
                      <span>
                        <Icon name="ArrowRight" />
                        {translate('ci_address')}&nbsp;
                        <a target="_blank" rel="noreferrer" href={lang === 'nl' ? "https://www.ejustice.just.fgov.be/cgi_loi/loi_a1.pl?language=nl&la=N&cn=2019032309&table_name=wet&&caller=list&N&fromtab=wet&tri=dd+AS+RANK&rech=1&numero=1&sql=(text+contains+(%27%27))#Art.2:19" : "https://www.ejustice.just.fgov.be/cgi_loi/loi_a1.pl?language=fr&la=F&cn=2019032309&table_name=loi&&caller=list&F&fromtab=loi&tri=dd+AS+RANK&rech=1&numero=1&sql=(text+contains+(%27%27))#LNK0033"} className="sub bold">
                          {translate('is_required_by_law')}
                        </a>
                      </span>
                    </div>
                    <span style={{ "whiteSpace": "nowrap" }}>
                      <Icon name="ArrowRight" />
                      {translate('tel_mob')}&nbsp;
                      <a className="sub bold tel_mob" target="_blank" rel="noreferrer" href={lang === 'nl' ? "https://news.economie.fgov.be/203683-deze-info-moet-u-zeker-vermelden-op-uw-bedrijfswebsite" : "https://news.economie.fgov.be/203681-informations-obligatoires-sur-le-site-web-de-votre-entreprise"} >
                        {translate('number_required_by_law')}
                      </a>
                    </span>
                    <span>
                      <Icon name="ArrowRight" />
                      {translate('ci_fax')} {translate('not_Required')}
                    </span>
                  </div>
                </div>
              </div>
              <div className="bottom">
                <p className="email">
                  {translate('ci_email_login')}&nbsp;
                  <a href={lang === 'nl' ? "https://www.ejustice.just.fgov.be/cgi_loi/loi_a1.pl?language=nl&la=N&cn=2019032309&table_name=wet&&caller=list&N&fromtab=wet&tri=dd+AS+RANK&rech=1&numero=1&sql=(text+contains+(%27%27))#Art.2:19" : "https://www.ejustice.just.fgov.be/cgi_loi/loi_a1.pl?language=fr&la=F&cn=2019032309&table_name=loi&&caller=list&F&fromtab=loi&tri=dd+AS+RANK&rech=1&numero=1&sql=(text+contains+(%27%27))#LNK0033"} target="_blank" className="sub">
                    {translate('legally_required')}
                  </a>
                  <Icon name="ArrowRight" />
                  <span>******@***************.be</span>
                </p>
                {lang === 'nl' ?
                  <span className="help">
                    <Icon name="Info" />
                    Ondanks deze verplichting zijn wij erin geslaagd om met FOD Economie tot een vergelijk te komen en <b className="yellow">tonen</b> wij, om SPAM te voorkomen, <b className="yellow">NOOIT een e-mail adres</b>! Er werd een contactformulier ontwikkeld dat 100% tegemoet komt aan de wettelijke normvereisten.
                  </span>
                  : lang === 'fr' ?
                    <span className="help">
                      <Icon name="Info" />
                      Malgré cette obligation, nous avons réussi à trouver un accord avec le SPF Economie et nous ne <b className="yellow">montrons JAMAIS</b> une <b className="yellow">adresse e-mail</b> pour éviter le SPAM ! Un formulaire de contact a été développé qui répond à 100% aux exigences de la norme légale.
                    </span>
                    :
                    <span className="help">
                      <Icon name="Info" />
                      Despite this obligation, we have succeeded in reaching an agreement with the FPS Economy and we <b className="yellow">NEVER show</b> an <b className="yellow">e-mail address</b> to prevent SPAM! A contact form has been developed that meets 100% of the legal standard requirements.
                    </span>
                }
                <p>
                  {translate('ci_main_activity')} <span className="yellow">{translate('in_cbe')}</span>
                </p>
                <button className={`select-button active ${data.customerCategory ? "red" : ""}`}>
                  {data.mainActivity && <p>{data.mainActivity.name}</p>}
                </button>
                {data.customerCategory &&
                  <p>
                    {translate('ci_customized_activity')}:
                  </p>
                }
                {data.customerCategory &&
                  <button className={["select-button", "active"].join(" ")}>
                    <p>{data.customerCategory.name}</p>
                  </button>
                }
              </div>
            </div>

            <div className="form">
              <form>
                <div>
                  <p>{translate('sign_up_and_fill_in')}</p>
                  {!hasAcount ? <p>{translate('register_with_personal_otp')} ({translate('otp')})</p> : <p>{translate('here_you_can_log_in_with')}</p>}
                </div>
                <div style={{ width: "100%", marginTop: "50px" }}>
                  {hasAcount ? (
                    <div className="inputs">
                      <div className="input__container"></div>
                      {error && <span className="error">{error}</span>}
                      <div className="input__container">
                        <label>
                          <b>{translate('ci_mail')}</b>
                          <input onChange={(e) => setEmail(e.target.value)} required className={"input"} type="text" />
                        </label>
                      </div>
                      {!forgotPassword ? (
                        <div className="input__container">
                          <label>
                            <b>{translate('password')}</b>
                            <input onChange={(e) => setPassword(e.target.value)} required className={["input", "underline"].join(" ")} type={seePassword ? "text" : "password"} />
                            <span id="passwordIcon" ><Icon name={seePassword ? "PasswordShow" : "PasswordHide"} onClick={() => setSeePassword(!seePassword)} /></span>
                          </label>
                        </div>
                      ) : (
                        <div className="input__container">
                          {lang === 'nl' ? <p style={{ fontSize: "12px" }}>
                            We sturen jou een e-mail om jouw wachtwoord te resetten, kijk zeker ook eens in <span style={{ color: "var(--sub)" }}>jouw spambox!</span>
                          </p>
                            : lang === 'fr' ? <p style={{ fontSize: "12px" }}>
                              Nous vous enverrons un e-mail pour réinitialiser votre mot de passe, pensez à vérifier <span style={{ color: "var(--sub)" }}>votre boîte spam !</span>
                            </p>
                              : <p style={{ fontSize: "12px" }}>
                                We will send you an e-mail to reset your password, be sure to check  <span style={{ color: "var(--sub)" }}>your spam box!</span>
                              </p>}
                        </div>
                      )}
                      {!forgotPassword ? (
                        <p onClick={() => setForgotPassword(!forgotPassword)} className="forgotPass hasAccount">
                          {translate('forgot_password')}
                        </p>
                      ) : (
                        <p onClick={() => setForgotPassword(!forgotPassword)} className="forgotPass hasAccount">
                          Log in
                        </p>
                      )}
                    </div>
                  ) : (
                    <div className="inputs">
                      <div className="input__container">
                        <label>
                          <b>{translate('your_enterprise_number')}</b>
                          <input defaultValue={ent} required className="input nummer" type="text" disabled />
                        </label>
                      </div>
                      <div className="input__container">
                        <label>
                          <b>{est ? translate('your_establishment_number') : translate('your_enterprise_number')}</b>
                          <input ref={btwRef} defaultValue={est} required className={["input", btwRef.current && btwRef.current.value ? (btwRef.current.value.length === 0 ? "underline" : "") : ""].join(" ")} type="text" disabled />
                        </label>
                      </div>
                      <div className="input__container">
                        <label>
                          <b>OTP ({translate('otp')})</b>
                          <input ref={OTPref} required className={["input", "underline"].join(" ")} type="password" />
                        </label>
                      </div>
                      <a
                        href={`mailto:support@ondernemersnetwerk.be?subject=Aanvraag OTP ${est}&body=Beste %0D%0D Graag had ik mijn OTP gekregen om mijn gegevens te optimaliseren! %0D U kan mijn contacteren via onderstaande gegevens: %0D%0D Ondernemingsnummer: ${ent} %0D Vestigingnummer: ${est} %0D Voornaam: %0D Naam: %0D Telefoonnummer:%0DEmail: %0D%0D Met vriendelijke groeten`}
                        className="forgotPass hasAccount"
                      >
                        {translate('dont_have_otp_yet')}
                      </a>
                    </div>
                  )}

                  <input onClick={(e) => login(e)} className="submit" type="submit" value={hasAcount ? (forgotPassword ? translate('reset_password') : "LOG IN") : translate('ok_ready')} />
                </div>
                <p
                  className="hasAccount"
                  onClick={() => {
                    setForgotPassword(false);
                    setHasAcount(!hasAcount);
                  }}
                >
                  {hasAcount ? translate('i_dont_have_account') : translate('i_already_have_account')}
                </p>
              </form>
            </div>
          </section>
        </Fragment>
      )}
      <NotificationContainer />
    </section>
  );
};

export default Login;
