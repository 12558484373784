import { useState, Fragment, useEffect } from "react";
import axios from "axios";
import store from "../store";
import Icon from "@identitybuilding/idb-react-iconlib";
import { Button, OsnCheckbox } from "@identitybuilding/idb-react-ui-elements";
import { useDispatch, useSelector } from "react-redux";
import { updateCurrentEstablishment } from "../actions/GeneralAction";
import { useHistory, useParams } from "react-router-dom";


function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return () => setValue(value => value + 1); // update state to force render
  // An function that increment 👆🏻 the previous state like here 
  // is better than directly setting `value + 1`
}

const Tijdschema = (props) => {
  const [data, setData] = useState([]);
  const [loaded, setLoaded] = useState(true);
  let [title, setTitle] = useState("");
  let [somethingChanged, setSomethingChanged] = useState(false);
  const lang = useSelector((state) => state.general.lang);
  const translate = useSelector((state) => state.general.translate);
  const dispatch = useDispatch();
  const forceUpdate = useForceUpdate();
  let { est, ent } = useParams();

  const fetchData = () => {
    let as_model = require(`../models/Timetable`).default;
    let copy = props.data
    let result = as_model(copy.timetables, lang)
    copy.timetables = result
    setData(copy);
    dispatch(updateCurrentEstablishment(copy));
    setLoaded(false);
    setLoaded(true);

  };


  const validateTimetableForm = () => {
    const exceptionsWithEmptyFromOrUntilTimeslots = data.timetables.find(timetable => {
      return timetable.exceptions.find(exception => {
        return exception.slots.some(slot => !slot.from || !slot.until) && !exception.closed
      }) != null
    });
    if (exceptionsWithEmptyFromOrUntilTimeslots) {
      
      if (lang === "nl") {
        props.createNotification("warning", "Vul de van en tot tijden in voor elke uitzondering")
      } else if (lang === "fr") {
        props.createNotification("warning", " Veuillez remplir les horaires de début et de fin pour chaque exception");
      } else if (lang === "de") {
        props.createNotification("warning", "Bitte füllen Sie die von und bis Zeiten für jede Ausnahme ein");
      } else {
        props.createNotification("warning", "Please fill in the from and until timeslots for each exception");
      }
      return false;
    }


    const exceptionsWithoutHolidayAndDate = data.timetables.find(timetable => {
      return timetable.exceptions.find(exception => {
        return !exception.holliday && !exception.date
      }) != null
    });

    if (exceptionsWithoutHolidayAndDate) {
      if (lang === "nl") {
        props.createNotification("error", "Selecteer een feestdag of een datum");
      } else if (lang === "fr") {
        props.createNotification("error", "Sélectionnez une fête ou une date");
      } else if (lang === "de") {
        props.createNotification("error", "Wählen Sie einen Feiertag oder eine Datum");
      } else {
        props.createNotification("error", "Select a holiday or a date");
      }
      return false;
    };

    return true;
  }



  const patchData = (data, tab) => {
    let copy = data
    if (tab > copy.available_tabs) { copy.available_tabs = tab }
    dispatch(updateCurrentEstablishment(copy));
    setData(copy)

    let as_model = require(`../models/ReverseTimetable`).default;
    let result = as_model(copy.timetables, lang)
    let newCopy = { ...copy }
    newCopy.timetables = result
    if (localStorage.getItem("token") && somethingChanged) {
      const headers = { "Content-Type": "application/json", Authorization: `Token ${localStorage.getItem("token")}` };
      axios.post(`${process.env.REACT_APP_BASE_MEDIA_URL}/ci/update/${est}/?lang=${lang}`, {
        headers: headers,
        data: newCopy,
        token: localStorage.getItem("token"),
        page: "timetables"
      })
        .then(async (res) => {
          setLoaded(false);
          setLoaded(true);
        });
      setSomethingChanged(false)
    }
  };

  // checked and working!
  const changeClosed = (event, type, day) => {
    let copy = data;
    copy.timetables[type].table[day][0].closed = !copy.timetables[type].table[day][0].closed;

    copy.timetables[type].table[day][0].from = "";
    copy.timetables[type].table[day][0].until = "";
    copy.timetables[type].table[day].length = 1;

    if (copy.timetables[type].global && !copy.timetables[type].table[day][0].afwijkend) {
      copy.timetables[type].table[day][0].afwijkend = true;
    }
    else if (!copy.timetables[type].table[day][0].closed) {
      copy.timetables[type].table[day][0].afwijkend = false;
    }
    // copy.timetables[type].table[day].afwijkend = "";

    setData(copy);
    forceUpdate();
    setLoaded(false);
    setSomethingChanged(true)
  };

  // checked and working!
  const changeAfwijkend = (event, type, day, value) => {
    let copy = data

    if (copy.timetables[type].table[day][0].closed && day !== "global") {
      copy.timetables[type].table[day][0].closed = false;
    }

    if (copy.timetables[type].table[day][0].afwijkend && day !== "global") {
      copy.timetables[type].table[day][0].afwijkend = false;
      copy.timetables[type].table[day][0].from = "";
      copy.timetables[type].table[day][0].until = ""
      // copy.timetables[type].table[day] = copy.timetables[type].table.global
    } else {
      copy.timetables[type].table[day][0].afwijkend = true;
    }
    copy.timetables[type].table.global[0].afwijkend = false;

    // let fullObjectcopy = data;
    // fullObjectcopy.timetables = copy;

    setData(copy);
    forceUpdate();
    setLoaded(true);
    setLoaded(false);
    setSomethingChanged(true)
  };

  const timetableHandler = (value, day, target, i, rowIndex) => {
    let copy = data;


    if (target === "from") {
      if (Number(value.replace(":", "")) > Number(copy.timetables[i].table[day][rowIndex].until.replace(":", "")) && Number(copy.timetables[i].table[day][rowIndex].until.replace(":", "")) !== 0) {
        props.createNotification("warn", "Gelieve een uur te nemen dat lager is dan de sluitingstijd ");
        return;
      }
    } else if (target === "until") {
      if (Number(value.replace(":", "")) < Number(copy.timetables[i].table[day][rowIndex].from.replace(":", ""))) {
        if (Number(value.replace(":", "")) < Number("0200") && Number(value.replace(":", "")) > Number("0001")) {
          value = "23:59";
        } else if (Number(copy.timetables[i].table[day][rowIndex].from.replace(":", "")) !== 0) {
          props.createNotification("warn", "Gelieve een uur te nemen dat hoger is dan de sluitingstijd ");
          return;
        }
      }
    }

    // if (day === "global") {
    //   copy.timetables[i].table.global[rowIndex][target] = value;

    //   if (!copy.timetables[i].table.monday[0].afwijkend) {
    //     copy.timetables[i].table.monday[rowIndex][target] = value;
    //   }
    //   if (!copy.timetables[i].table.tuesday[0].afwijkend) {
    //     copy.timetables[i].table.tuesday[rowIndex][target] = value;
    //   }
    //   if (!copy.timetables[i].table.wednesday[0].afwijkend) {
    //     copy.timetables[i].table.wednesday[rowIndex][target] = value;
    //   }
    //   if (!copy.timetables[i].table.thursday[0].afwijkend) {
    //     copy.timetables[i].table.thursday[rowIndex][target] = value;
    //   }
    //   if (!copy.timetables[i].table.friday[0].afwijkend) {
    //     copy.timetables[i].table.friday[rowIndex][target] = value;
    //   }
    //   if (!copy.timetables[i].table.saturday[0].afwijkend) {
    //     copy.timetables[i].table.saturday[rowIndex][target] = value;
    //   }
    //   if (!copy.timetables[i].table.sunday[0].afwijkend) {
    //     copy.timetables[i].table.sunday[rowIndex][target] = value;
    //   }
    // }
    if (typeof rowIndex === "number") {
      copy.timetables[i].table[day][rowIndex][target] = value;
    }

    if (day !== "global") {
      copy.timetables[i].table[day][rowIndex][target] = value;
    }

    if (copy.timetables[i].global === false) {
      if (target === "from") {
        if (copy.timetables[i].table.monday[rowIndex].from.length === 0) {
          copy.timetables[i].table.monday[rowIndex].from = value;
        }
        if (copy.timetables[i].table.tuesday[rowIndex].from.length === 0) {
          copy.timetables[i].table.tuesday[rowIndex].from = value;
        }
        if (copy.timetables[i].table.wednesday[rowIndex].from.length === 0) {
          copy.timetables[i].table.wednesday[rowIndex].from = value;
        }
        if (copy.timetables[i].table.thursday[rowIndex].from.length === 0) {
          copy.timetables[i].table.thursday[rowIndex].from = value;
        }
        if (copy.timetables[i].table.friday[rowIndex].from.length === 0) {
          copy.timetables[i].table.friday[rowIndex].from = value;
        }
        if (copy.timetables[i].table.saturday[rowIndex].from.length === 0) {
          copy.timetables[i].table.saturday[rowIndex].from = value;
        }
        if (copy.timetables[i].table.sunday[rowIndex].from.length === 0) {
          copy.timetables[i].table.sunday[rowIndex].from = value;
        }
      }
      if (target === "until") {
        if (copy.timetables[i].table.monday[rowIndex].until.length === 0) {
          copy.timetables[i].table.monday[rowIndex].until = value;
        }
        if (copy.timetables[i].table.tuesday[rowIndex].until.length === 0) {
          copy.timetables[i].table.tuesday[rowIndex].until = value;
        }
        if (copy.timetables[i].table.wednesday[rowIndex].until.length === 0) {
          copy.timetables[i].table.wednesday[rowIndex].until = value;
        }
        if (copy.timetables[i].table.thursday[rowIndex].until.length === 0) {
          copy.timetables[i].table.thursday[rowIndex].until = value;
        }
        if (copy.timetables[i].table.friday[rowIndex].until.length === 0) {
          copy.timetables[i].table.friday[rowIndex].until = value;
        }
        if (copy.timetables[i].table.saturday[rowIndex].until.length === 0) {
          copy.timetables[i].table.saturday[rowIndex].until = value;
        }
        if (copy.timetables[i].table.sunday[rowIndex].until.length === 0) {
          copy.timetables[i].table.sunday[rowIndex].until = value;
        }
      }
    }

    dispatch(updateCurrentEstablishment(copy));
    setData(copy);
    setLoaded(false);
    forceUpdate();
    setSomethingChanged(true)

  };

  const addTimetable = (type, i, title) => {
    const copy = data;
    let index;

    if (data.timetables) {
      index = data.timetables.length;
    } else {
      index = 0;
    }

    let firstTable = {
      global: type === "easy" ? true : false,
      name: `newDefault${index}`,
      periode: false,
      is_24_7: false,
      show_exceptions: false,
      exceptions: [],
      periode_list: [
        {
          message: false,
          periodeFrom: "",
          periodeMessage: "",
          periodeUntil: "",
        },
      ],
      table: {
        monday: [
          {
            closed: false,
            afwijkend: false,
            from: "",
            until: "",
          },
        ],
        tuesday: [
          {
            closed: false,
            afwijkend: false,
            from: "",
            until: "",
          },
        ],
        wednesday: [
          {
            closed: false,
            afwijkend: false,
            from: "",
            until: "",
          },
        ],
        thursday: [
          {
            closed: false,
            afwijkend: false,
            from: "",
            until: "",
          },
        ],
        friday: [
          {
            closed: false,
            afwijkend: false,
            from: "",
            until: "",
          },
        ],
        saturday: [
          {
            closed: false,
            afwijkend: false,
            from: "",
            until: "",
          },
        ],
        sunday: [
          {
            closed: false,
            afwijkend: false,
            from: "",
            until: "",
          },
        ],
        global: [
          {
            closed: false,
            afwijkend: false,
            from: "",
            until: "",
          },
        ],
      },
    };
    let newNotGlobal = {
      global: type === "easy" ? true : false,
      name: `newDefault${index}`,
      exceptions: [],
      periode: false,
      is_24_7: false,
      periode_list: [
        {
          message: false,
          periodeFrom: "",
          periodeMessage: "",
          periodeUntil: "",
        },
      ],
      table: {
        monday: [
          {
            closed: false,
            afwijkend: type === "easy" ? false : true,
            from: "",
            until: "",
          },
        ],
        tuesday: [
          {
            closed: false,
            afwijkend: type === "easy" ? false : true,
            from: "",
            until: "",
          },
        ],
        wednesday: [
          {
            closed: false,
            afwijkend: type === "easy" ? false : true,
            from: "",
            until: "",
          },
        ],
        thursday: [
          {
            closed: false,
            afwijkend: type === "easy" ? false : true,
            from: "",
            until: "",
          },
        ],
        friday: [
          {
            closed: false,
            afwijkend: type === "easy" ? false : true,
            from: "",
            until: "",
          },
        ],
        saturday: [
          {
            closed: false,
            afwijkend: type === "easy" ? false : true,
            from: "",
            until: "",
          },
        ],
        sunday: [
          {
            closed: false,
            afwijkend: type === "easy" ? false : true,
            from: "",
            until: "",
          },
        ],
        global: [
          {
            closed: false,
            afwijkend: type === "easy" ? false : true,
            from: "",
            until: "",
          },
        ],
      },
    };

    if (!data.timetables) {
      let copy = data;

      copy.timetables = [firstTable];
    } else {
      if (type === "easy" || type === "complicated") {
        copy.timetables.push(newNotGlobal);
      }

      if (type === "title" && typeof i === "number") {
        copy.timetables[i].name = title;
      }
    }

    setData(copy);
    forceUpdate();
    setLoaded(false);
    setSomethingChanged(true)
  };

  const deleteTable = (e, index) => {
    let copy = data;
    copy.deleted_timetables.push(copy.timetables[index])
    copy.timetables.splice(index, 1);
    setData(copy);
    forceUpdate();
    setSomethingChanged(true)
    setLoaded(false);
  };

  const changeTitle = (e, index) => {
    for (let i = 0; i < e.target.children.length; i++) {
      if (e.target.children[i].selected) {
        title = e.target.children[i].value;
        addTimetable("title", index, title);
      }
    }
    forceUpdate();
  };

  const change247 = (e, index) => {
    let copy = data;
    copy.timetables[index].is_24_7 = !copy.timetables[index].is_24_7
    setData(copy);
    setLoaded(false);
    setSomethingChanged(true)
    forceUpdate();
  };

  const AddTime = (e, index, day) => {
    let copy = [...data.timetables];
    let newRow = {
      afwijkend: false,
      closed: false,
      from: "",
      until: "",
    };
    let Row = {
      afwijkend: copy[index].table[day][0].afwijkend,
      closed: false,
      from: "",
      until: "",
    };
    if (day === "global" && copy[index].global === false) {
      copy[index].table.global[0].afwijkend = false;
      for (let i = 0; i < 1; i++) {
        copy[index].table.global.push(newRow);
      }
      for (let i = 0; i < 1; i++) {
        if (!copy[index].table.monday[0].afwijkend) {
          let test = [...copy[index].table.global];
          copy[index].table.monday = test;
        }
      }
      for (let i = 0; i < 1; i++) {
        if (!copy[index].table.tuesday[0].afwijkend) {
          let test = [...copy[index].table.global];
          copy[index].table.tuesday = test;
        }
      }
      for (let i = 0; i < 1; i++) {
        if (!copy[index].table.wednesday[0].afwijkend) {
          let test = [...copy[index].table.global];
          copy[index].table.wednesday = test;
        }
      }
      for (let i = 0; i < 1; i++) {
        if (!copy[index].table.thursday[0].afwijkend) {
          let test = [...copy[index].table.global];
          copy[index].table.thursday = test;
        }
      }
      for (let i = 0; i < 1; i++) {
        if (!copy[index].table.friday[0].afwijkend) {
          let test = [...copy[index].table.global];
          copy[index].table.friday = test;
        }
      }
      for (let i = 0; i < 1; i++) {
        if (!copy[index].table.saturday[0].afwijkend) {
          let test = [...copy[index].table.global];
          copy[index].table.saturday = test;
        }
      }
      for (let i = 0; i < 1; i++) {
        if (!copy[index].table.sunday[0].afwijkend) {
          let test = [...copy[index].table.global];
          copy[index].table.sunday = test;
        }
      }
    } else {
      for (let i = 0; i < 1; i++) {
        copy[index].table[day].push(Row);
      }
    }


    let fullObjectcopy = data;
    fullObjectcopy.timetables = copy;

    setData(fullObjectcopy);
    setLoaded(false);
    setSomethingChanged(true)
    forceUpdate();
  };

  const removeTime = (e, index, rowIndex, day) => {

    let copy = [...data.timetables];

    copy[index].table[day].splice(rowIndex, 1);

    if (rowIndex === 0 && day !== "global") {
      copy[index].table[day][1].afwijkend = true;
    }

    if (day === "global" && copy[index].global === false) {
      // if (day === "global" && copy[index].global === false) {
      if (!copy[index].table.monday[0].afwijkend) {
        copy[index].table.monday = copy[index].table.global;
      }
      if (!copy[index].table.tuesday[0].afwijkend) {
        copy[index].table.tuesday = copy[index].table.global;
      }
      if (!copy[index].table.wednesday[0].afwijkend) {
        copy[index].table.wednesday = copy[index].table.global;
      }
      if (!copy[index].table.thursday[0].afwijkend) {
        copy[index].table.thursday = copy[index].table.global;
      }
      if (!copy[index].table.friday[0].afwijkend) {
        copy[index].table.friday = copy[index].table.global;
      }
      if (!copy[index].table.saturday[0].afwijkend) {
        copy[index].table.saturday = copy[index].table.global;
      }
      if (!copy[index].table.sunday[0].afwijkend) {
        copy[index].table.sunday = copy[index].table.global;
      }
    }

    let fullObjectcopy = data;
    fullObjectcopy.timetables = copy;

    setData(fullObjectcopy);
    setLoaded(false);
    forceUpdate();
    setSomethingChanged(true)
  };

  const addPeriode = (e, index, type, periodeIndex) => {
    let copy = data;
    if (type === "check") {
      copy.timetables[index].periode = !copy.timetables[index].periode;

      if (copy.timetables[index].periode === false) {
        copy.timetables[index].periode_list.length = 1;
        copy.timetables[index].periode_list[0].periodeFrom = "";
        copy.timetables[index].periode_list[0].periodeUntil = "";
        copy.timetables[index].periode_list[0].periodeMessage = "";
      }
    }

    if (type === "from") {
      copy.timetables[index].periode_list[periodeIndex].periodeFrom = e.target.value;
    }
    if (type === "until") {
      copy.timetables[index].periode_list[periodeIndex].periodeUntil = e.target.value;
    }
    if (type === "message") {
      copy.timetables[index].periode_list[periodeIndex].periodeMessage = e.target.value;
    }

    if (type === "addPeriode") {
      copy.timetables[index].periode_list.push({
        periodeFrom: "",
        periodeMessage: "",
        periodeUntil: "",
        message: false,
      });
    }
    if (type === "removePeriode") {
      copy.timetables[index].periode_list.splice(periodeIndex, 1);
    }

    if (type === "toggleTrue") {
      copy.timetables[index].periode_list[periodeIndex].message = true;
    }
    if (type === "toggleFalse") {
      copy.timetables[index].periode_list[periodeIndex].message = false;
      copy.timetables[index].periode_list[periodeIndex].periodeMessage = "";
    }

    setData(copy);
    setLoaded(false);
    forceUpdate();
    setSomethingChanged(true)
  };

  const addSpecificMessage = (value, day, index, rowIndex, type) => {
    let copy = data;
    if (type === "check") {
      copy.timetables[index].table[day][rowIndex].message = !copy.timetables[index].table[day][rowIndex].message;

      if (copy.timetables[index].table[day][rowIndex].message === false) {
        copy.timetables[index].table[day][rowIndex].messageText = "";
      } else {
        copy.timetables[index].table[day][rowIndex].messageText = " ";

      }
    }

    if (type === "message") {
      copy.timetables[index].table[day][rowIndex].messageText = value;
    }
    setData(copy);
    setLoaded(false);
    setSomethingChanged(true)
    forceUpdate();
  };


  const changeTimetableMessage = (e, index, type) => {
    let copy = data
    copy.timetables[index].message = e.target.value
    setData(copy);
    setLoaded(false);
    setSomethingChanged(true)
    forceUpdate();
  };

  const changeExceptionType = (e, index, idx, type, rowIndex) => {
    let copy = data
    if (type === "other") { copy.timetables[index].exceptions[idx].other = !copy.timetables[index].exceptions[idx].other }
    if (type === "type") { copy.timetables[index].exceptions[idx].holliday = e.target.value }
    if (type === "date") { copy.timetables[index].exceptions[idx].date = e.target.value }
    if (type === "from") { copy.timetables[index].exceptions[idx].slots[rowIndex].from = e.target.value }
    if (type === "until") { copy.timetables[index].exceptions[idx].slots[rowIndex].until = e.target.value }
    if (type === "messageAdd") { copy.timetables[index].exceptions[idx].slots[rowIndex].messageText = "" }
    if (type === "messageRemove") { copy.timetables[index].exceptions[idx].slots[rowIndex].messageText = null }
    if (type === "messageChange") { copy.timetables[index].exceptions[idx].slots[rowIndex].messageText = e.target.value }
    if (type === "addTime") { copy.timetables[index].exceptions[idx].slots.push({ "from": "", "until": "", "messageText": null }) }
    if (type === "removeTime") { copy.timetables[index].exceptions[idx].slots.splice(rowIndex, 1) }
    if (type === "closed") {
      copy.timetables[index].exceptions[idx].closed = !copy.timetables[index].exceptions[idx].closed
      copy.timetables[index].exceptions[idx].slots = [{ "from": "", "until": "", "messageText": null }]
    }
    if (type === "addException") {
      copy.timetables[index].exceptions.push({
        "date": null,
        "other": false,
        "holliday": "",
        "message": null,
        "closed": false,
        "slots": [
          {
            "from": "",
            "until": "",
            "messageText": null
          }
        ]
      })
    }
    if (type === "removeException") { copy.timetables[index].exceptions.splice(idx, 1) }


    setLoaded(false);
    setSomethingChanged(true)
    forceUpdate();
  };


  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    setSomethingChanged(false)
    fetchData()
  }, []);

  return (
    <section className="c-timetables">
      <div className="intro">
        <h2>
          <span className="typo">10</span>
        </h2>
        <div>
          <h2>{translate('ci_timetables')}</h2>
          <p>
            {translate('research_has_shown')} {translate('nobody_likes')} <span className="yellow bold">{translate('all_possibilities')}</span> {translate('so_that_everything_is_clear')}
          </p>
          <p>
            {translate('do_you_have_diffrent_hours')} <span className="yellow bold">{translate('this_can_aal_be_made_clear')}</span>
          </p>
        </div>
      </div>
      <div className="section-container">
        <section className="add-button__container">
          <div className="add-timetable">
            <div className="timetable-data">
              <h2>{translate('simple_time_schedule')}</h2>
              <p>{translate('my_working_hours_regular')}</p>
              <h5>{translate('ci_example')}</h5>
              <p className="example">
                {translate('monday_till_firday')}
                <br /> {translate('closed_on_weekend')}
              </p>
            </div>
            <div className="add-container">
              <Icon name="AddCircle" onClick={(e) => addTimetable("easy")} />
            </div>
          </div>
          <div className="add-timetable">
            <div className="timetable-data">
              <h2>{translate('complicated_timetable')}</h2>
              <p>{translate('my_working_hours_irregular')}</p>
              <h5>{translate('ci_example')}</h5>
              <p className="example">
                {translate('monday_wednesday_friday')} <br />
                {translate('tuesday_thrusday')} <br />
                {translate('closed_on_weekend')}
              </p>
            </div>
            <div className="add-container">
              <Icon name="AddCircle" onClick={(e) => addTimetable("complicated")} />
            </div>
          </div>
        </section>
        <Fragment>
          {data && data.timetables && data.timetables.length > 0 && (
            <div className="c-timetables__item-container">
              {data.timetables.map((item, index) => (
                <div key={index} className={`c-timetables__item`}>
                  <div className="c-timetables_table">
                    <table>
                      <thead>
                        <tr>
                          <td>
                            <div>
                              <p>{translate('type_of_opening_hours')}</p>
                            </div>
                            <select onChange={(e) => changeTitle(e, index)}>
                              {item.name.includes("newDefault") && <option>Selecteer 1</option>}
                              {data.timetable_types.map((type, idx) => (
                                item.name === type.title && <option key={idx} value={type.title}>{type.title}</option>
                              ))}
                              {data.timetable_types.map((type, idx) => (
                                item.name !== type.title && <option key={idx} value={type.title}>{type.title}</option>
                              ))}
                              {/* {item.name === "office" && <option value={"office"}>Kantoor</option>}
                                {item.name === "Feestdagen" && <option value={"holiday"}>Feestdagen</option>}
                                {item.name === "Winkel" && <option value={"shop"}>Winkel</option>}
                                {item.name === "showroom" && <option value={"showroom"}>Showroom</option>}
                                {item.name === "garage" && <option value={"garage"}>Garage</option>}
                                {item.name === "workenvirement" && <option value={"workenvirement"}>Werkplek</option>}
                                {item.name !== "Winkel" && <option value={"shop"}>Winkel</option>}
                                {item.name !== "showroom" && <option value={"showroom"}>Showroom</option>}
                                {item.name !== "Garage" && <option value={"garage"}>Garage</option>}
                                {item.name !== "workenvirement" && <option value={"workenvirement"}>Werkplek</option>}
                                {item.name !== "office" && <option value={"office"}>Kantoor</option>}
                                {item.name !== "holiday" && <option value={"holiday"}>Feestdagen</option>} */}
                            </select>
                            <div className="twentyfourseven">
                              <div onClick={(e) => change247(e, index)} className={`checkBox ${data.timetables[index].is_24_7 ? 'active' : ''}`}>
                                <label htmlFor={`checkBox`}>
                                  <input type="checkbox" checked={data.timetables[index].is_24_7} onChange={() => 'ok'} />
                                  <div className={`checkboxBox ${data.timetables[index].is_24_7 ? 'active' : ''}`}>
                                    <div className='checkboxBoxSelected' />
                                  </div>
                                </label>
                              </div>
                              <span>24/7 {lang === 'fr' ? 'ouvert' : 'open'}</span>

                            </div>
                          </td>
                        </tr>
                      </thead>

                      {data.timetables[index].is_24_7 ?
                        <tbody><tr className="twentyfoursevenMessage">
                          {lang === 'nl' ?
                            <td>Jouw tijdschema staat op <b className="yellow">&nbsp;24/7&nbsp;</b> open ingesteld!</td>
                            : lang === 'fr' ?
                              <td>Votre emploi du temps est prévu pour une ouverture <b className="yellow">&nbsp;24/7&nbsp;</b></td>
                              :
                              <td>Your timetable is set to <b className="yellow">&nbsp;24/7&nbsp;</b>open!</td>
                          }
                        </tr></tbody>
                        :
                        <tbody>
                          {!data.timetables[index].global && <tr />}
                          {data.timetables[index].global && (
                            <tr>
                              <td>{translate('standard_hours')}</td>
                              <td className="row-container">
                                {data.timetables[index].table.global.map((item, rowIndex) => (
                                  <span key={rowIndex} className={[data.timetables[index].table.global.length > 1 ? "multiple" : "", "time-row"].join(" ")}>
                                    <div>
                                      <p>
                                        <input onBlur={(e) => timetableHandler(e.target.value, "global", "from", index, rowIndex)} defaultValue={item.from} type="time" />
                                      </p>
                                      <p className="score">-</p>
                                      <p>
                                        <input defaultValue={item.until} onBlur={(e) => timetableHandler(e.target.value, "global", "until", index, rowIndex)} type="time" />
                                      </p>
                                      {!data.timetables[index].table.global[rowIndex].messageText && <img onClick={(e) => addSpecificMessage(e.target.value, "global", index, rowIndex, "check")} alt="message toggle" width="30px" src="https://static.thenounproject.com/png/3144347-200.png" />}

                                      {data.timetables[index].table.global[rowIndex].messageText && <img onClick={(e) => addSpecificMessage(e.target.value, "global", index, rowIndex, "check")} alt="message toggle" width="30px" src="https://static.thenounproject.com/png/3144348-200.png" />}
                                      {data.timetables[index].table.global.length !== 1 && <Icon name="Bin" onClick={(e) => removeTime(e, index, rowIndex, "global")} key={rowIndex} />}

                                      <Icon onClick={(e) => AddTime(e, index, "global")} name="AddCircle" />
                                    </div>
                                    {data.timetables[index].table.global[rowIndex].messageText && (
                                      <div className="message">
                                        <input defaultValue={data.timetables[index].table.global[rowIndex].messageText} onBlur={(e) => addSpecificMessage(e.target.value, "global", index, rowIndex, "message")} />
                                      </div>
                                    )}
                                  </span>
                                ))}
                              </td>
                              <td></td>

                              <td></td>
                            </tr>
                          )}

                          <tr className="header">
                            <th></th>
                            <th></th>
                            <th>{translate('ci_closed')}?</th>
                            {data.timetables[index].global && <th>{translate('ci_divergent')}?</th>}
                          </tr>
                          <tr>
                            <td>{translate('ci_monday')}</td>
                            {!data.timetables[index].table.monday[0].closed && data.timetables[index].table.monday[0].afwijkend && (
                              <td className="row-container">
                                {data.timetables[index].table.monday.map((item, rowIndex) => (
                                  <span key={rowIndex} className={[data.timetables[index].table.monday.length > 1 ? "multiple" : "", "time-row"].join(" ")}>
                                    <div>
                                      <p>
                                        <input onBlur={(e) => timetableHandler(e.target.value, "monday", "from", index, rowIndex)} defaultValue={item.from} type="time" />
                                      </p>
                                      <p className="score">-</p>
                                      <p>
                                        <input defaultValue={item.until} onBlur={(e) => timetableHandler(e.target.value, "monday", "until", index, rowIndex)} type="time" />
                                      </p>
                                      {!data.timetables[index].table.monday[rowIndex].messageText && <img onClick={(e) => addSpecificMessage(e.target.value, "monday", index, rowIndex, "check")} alt="message toggle" width="30px" src="https://static.thenounproject.com/png/3144347-200.png" />}

                                      {data.timetables[index].table.monday[rowIndex].messageText && <img onClick={(e) => addSpecificMessage(e.target.value, "monday", index, rowIndex, "check")} alt="message toggle" width="30px" src="https://static.thenounproject.com/png/3144348-200.png" />}
                                      <Icon onClick={(e) => AddTime(e, index, "monday")} name="AddCircle" />
                                    </div>
                                    {data.timetables[index].table.monday[rowIndex].messageText && (
                                      <div className="message">
                                        <input defaultValue={data.timetables[index].table.monday[rowIndex].messageText} onBlur={(e) => addSpecificMessage(e.target.value, "monday", index, rowIndex, "message")} />
                                      </div>
                                    )}
                                  </span>
                                ))}
                              </td>
                            )}
                            {!data.timetables[index].global && !data.timetables[index].table.monday[0].closed && data.timetables[index].table.monday[0].afwijkend === false && (
                              <td className="row-container">
                                {data.timetables[index].table.monday.map((item, rowIndex) => (
                                  <span key={rowIndex} className={[data.timetables[index].table.monday.length > 1 ? "multiple" : "", "time-row"].join(" ")}>
                                    <div>
                                      <p>
                                        <input onBlur={(e) => timetableHandler(e.target.value, "monday", "from", index, rowIndex)} defaultValue={item.from} type="time" />
                                      </p>
                                      <p className="score">-</p>
                                      <p>
                                        <input defaultValue={item.until} onBlur={(e) => timetableHandler(e.target.value, "monday", "until", index, rowIndex)} type="time" />
                                      </p>
                                      {!data.timetables[index].table.monday[rowIndex].messageText && <img onClick={(e) => addSpecificMessage(e.target.value, "monday", index, rowIndex, "check")} alt="message toggle" width="30px" src="https://static.thenounproject.com/png/3144347-200.png" />}

                                      {data.timetables[index].table.monday[rowIndex].messageText && <img onClick={(e) => addSpecificMessage(e.target.value, "monday", index, rowIndex, "check")} alt="message toggle" width="30px" src="https://static.thenounproject.com/png/3144348-200.png" />}
                                      <Icon onClick={(e) => AddTime(e, index, "monday")} name="AddCircle" />
                                    </div>
                                    {data.timetables[index].table.monday[rowIndex].messageText && (
                                      <div className="message">
                                        <input defaultValue={data.timetables[index].table.monday[rowIndex].messageText} onBlur={(e) => addSpecificMessage(e.target.value, "monday", index, rowIndex, "message")} />
                                      </div>
                                    )}
                                  </span>
                                ))}
                              </td>
                            )}
                            {data.timetables[index].table.monday[0].closed && (
                              <td className="closed">
                                <span className="text">{translate('ci_closed')}</span>
                              </td>
                            )}
                            {data.timetables[index].global && !data.timetables[index].table.monday[0].closed && !data.timetables[index].table.monday[0].afwijkend && (
                              <td className="closed row-container">
                                {!data.timetables[index].table.monday[0].closed && data.timetables[index].table.monday.map((item, rowIndex) => (
                                  <span key={rowIndex} className={[data.timetables[index].table.monday.length > 1 ? "multiple" : "", "time-row"].join(" ")}>
                                    <div>
                                      <p>
                                        <input disabled defaultValue={item.from} type="time" />
                                      </p>
                                      <p className="score">-</p>
                                      <p>
                                        <input disabled defaultValue={item.until} type="time" />
                                      </p>
                                    </div>
                                  </span>
                                ))}
                              </td>
                            )}

                            {!data.timetables[index].global && <td />}
                            <td>
                              {data.timetables[index].table.monday.length < 2 && (
                                <OsnCheckbox
                                  checked={data.timetables[index].table.monday[0].closed}
                                  name={String(`${index} table monday 0 closed`)}
                                  onChange={(e) => {
                                    changeClosed(e, index, "monday");
                                  }}
                                />
                              )}
                              {data.timetables[index].table.monday.length > 1 && (data.timetables[index].table.monday[0].afwijkend || !data.timetables[index].global) && (
                                <span className="row-container">
                                  {data.timetables[index].table.monday.map((item, rowIndex) => (
                                    <Icon name="Bin" onClick={(e) => removeTime(e, index, rowIndex, "monday")} key={rowIndex} />
                                  ))}
                                </span>
                              )}
                            </td>

                            {data.timetables[index].global && (
                              <td>
                                <OsnCheckbox
                                  checked={data.timetables[index].table.monday[0].afwijkend}
                                  name={String(`${index} table monday 0 afwijkend`)}
                                  onChange={(e) => {
                                    changeAfwijkend(e, index, "monday", data.timetables[index].table.monday[0].afwijkend);
                                  }}
                                />
                              </td>
                            )}
                          </tr>
                          <tr>
                            <td>{translate('ci_tuesday')}</td>
                            {!data.timetables[index].table.tuesday[0].closed && data.timetables[index].table.tuesday[0].afwijkend && (
                              <td className="row-container">
                                {data.timetables[index].table.tuesday.map((item, rowIndex) => (
                                  <span key={rowIndex} className={[data.timetables[index].table.tuesday.length > 1 ? "multiple" : "", "time-row"].join(" ")}>
                                    <div>
                                      <p>
                                        <input defaultValue={item.from} onBlur={(e) => timetableHandler(e.target.value, "tuesday", "from", index, rowIndex)} type="time" />
                                      </p>
                                      <p className="score">-</p>
                                      <p>
                                        <input defaultValue={item.until} onBlur={(e) => timetableHandler(e.target.value, "tuesday", "until", index, rowIndex)} type="time" />
                                      </p>
                                      {!data.timetables[index].table.tuesday[rowIndex].messageText && <img onClick={(e) => addSpecificMessage(e.target.value, "tuesday", index, rowIndex, "check")} alt="message toggle" width="30px" src="https://static.thenounproject.com/png/3144347-200.png" />}

                                      {data.timetables[index].table.tuesday[rowIndex].messageText && <img onClick={(e) => addSpecificMessage(e.target.value, "tuesday", index, rowIndex, "check")} alt="message toggle" width="30px" src="https://static.thenounproject.com/png/3144348-200.png" />}
                                      <Icon onClick={(e) => AddTime(e, index, "tuesday")} name="AddCircle" />
                                    </div>
                                    {data.timetables[index].table.tuesday[rowIndex].messageText && (
                                      <div className="message">
                                        <input defaultValue={data.timetables[index].table.tuesday[rowIndex].messageText} onBlur={(e) => addSpecificMessage(e.target.value, "tuesday", index, rowIndex, "message")} />
                                      </div>
                                    )}
                                  </span>
                                ))}
                              </td>
                            )}
                            {!data.timetables[index].global && !data.timetables[index].table.tuesday[0].closed && data.timetables[index].table.tuesday[0].afwijkend === false && (
                              <td className="row-container">
                                {data.timetables[index].table.tuesday.map((item, rowIndex) => (
                                  <span key={rowIndex} className={[data.timetables[index].table.tuesday.length > 1 ? "multiple" : "", "time-row"].join(" ")}>
                                    <div>
                                      <p>
                                        <input defaultValue={item.from} onBlur={(e) => timetableHandler(e.target.value, "tuesday", "from", index, rowIndex)} type="time" />
                                      </p>
                                      <p className="score">-</p>
                                      <p>
                                        <input defaultValue={item.until} onBlur={(e) => timetableHandler(e.target.value, "tuesday", "until", index, rowIndex)} type="time" />
                                      </p>
                                      {!data.timetables[index].table.tuesday[rowIndex].messageText && <img onClick={(e) => addSpecificMessage(e.target.value, "tuesday", index, rowIndex, "check")} alt="message toggle" width="30px" src="https://static.thenounproject.com/png/3144347-200.png" />}

                                      {data.timetables[index].table.tuesday[rowIndex].messageText && <img onClick={(e) => addSpecificMessage(e.target.value, "tuesday", index, rowIndex, "check")} alt="message toggle" width="30px" src="https://static.thenounproject.com/png/3144348-200.png" />}
                                      <Icon onClick={(e) => AddTime(e, index, "tuesday")} name="AddCircle" />
                                    </div>
                                    {data.timetables[index].table.tuesday[rowIndex].messageText && (
                                      <div className="message">
                                        <input defaultValue={data.timetables[index].table.tuesday[rowIndex].messageText} onBlur={(e) => addSpecificMessage(e.target.value, "tuesday", index, rowIndex, "message")} />
                                      </div>
                                    )}
                                  </span>
                                ))}
                              </td>
                            )}
                            {data.timetables[index].table.tuesday[0].closed && (
                              <td className="closed">
                                <span className="text">{translate('ci_closed')}</span>
                              </td>
                            )}
                            {data.timetables[index].global && !data.timetables[index].table.tuesday[0].afwijkend && (
                              <td className="closed row-container">
                                {!data.timetables[index].table.tuesday[0].closed && data.timetables[index].table.tuesday.map((item, rowIndex) => (
                                  <span key={rowIndex} className={[data.timetables[index].table.tuesday.length > 1 ? "multiple" : "", "time-row"].join(" ")}>
                                    <div>
                                      <p>
                                        <input disabled defaultValue={item.from} type="time" />
                                      </p>
                                      <p className="score">-</p>
                                      <p>
                                        <input disabled defaultValue={item.until} type="time" />
                                      </p>
                                    </div>
                                  </span>
                                ))}
                              </td>
                            )}
                            {!data.timetables[index].global && <td />}
                            <td>
                              {data.timetables[index].table.tuesday.length < 2 && (
                                <OsnCheckbox
                                  checked={data.timetables[index].table.tuesday[0].closed}
                                  name={String(`${index} table tuesday 0 closed`)}
                                  onChange={(e) => {
                                    changeClosed(e, index, "tuesday");
                                  }}
                                />
                              )}
                              {data.timetables[index].table.tuesday.length > 1 && (data.timetables[index].table.tuesday[0].afwijkend || !data.timetables[index].global) && (
                                <span className="row-container">
                                  {data.timetables[index].table.tuesday.map((item, rowIndex) => (
                                    <Icon name="Bin" onClick={(e) => removeTime(e, index, rowIndex, "tuesday")} key={rowIndex} />
                                  ))}
                                </span>
                              )}
                            </td>
                            {data.timetables[index].global && (
                              <td>
                                <OsnCheckbox
                                  checked={data.timetables[index].table.tuesday[0].afwijkend}
                                  name={String(`${index} table tuesday 0 afwijkend`)}
                                  onChange={(e) => {
                                    changeAfwijkend(e, index, "tuesday", data.timetables[index].table.tuesday[0].afwijkend);
                                  }}
                                />
                              </td>
                            )}
                          </tr>
                          <tr>
                            <td>{translate('ci_wednesday')}</td>
                            {!data.timetables[index].table.wednesday[0].closed && data.timetables[index].table.wednesday[0].afwijkend && (
                              <td className="row-container">
                                {data.timetables[index].table.wednesday.map((item, rowIndex) => (
                                  <span key={rowIndex} className={[data.timetables[index].table.wednesday.length > 1 ? "multiple" : "", "time-row"].join(" ")}>
                                    <div>
                                      <p>
                                        <input defaultValue={item.from} onBlur={(e) => timetableHandler(e.target.value, "wednesday", "from", index, rowIndex)} type="time" />
                                      </p>
                                      <p className="score">-</p>
                                      <p>
                                        <input defaultValue={item.until} onBlur={(e) => timetableHandler(e.target.value, "wednesday", "until", index, rowIndex)} type="time" />
                                      </p>
                                      {!data.timetables[index].table.wednesday[rowIndex].messageText && <img onClick={(e) => addSpecificMessage(e.target.value, "wednesday", index, rowIndex, "check")} alt="message toggle" width="30px" src="https://static.thenounproject.com/png/3144347-200.png" />}

                                      {data.timetables[index].table.wednesday[rowIndex].messageText && <img onClick={(e) => addSpecificMessage(e.target.value, "wednesday", index, rowIndex, "check")} alt="message toggle" width="30px" src="https://static.thenounproject.com/png/3144348-200.png" />}
                                      <Icon onClick={(e) => AddTime(e, index, "wednesday")} name="AddCircle" />
                                    </div>
                                    {data.timetables[index].table.wednesday[rowIndex].messageText && (
                                      <div className="message">
                                        <input defaultValue={data.timetables[index].table.wednesday[rowIndex].messageText} onBlur={(e) => addSpecificMessage(e.target.value, "wednesday", index, rowIndex, "message")} />
                                      </div>
                                    )}
                                  </span>
                                ))}
                              </td>
                            )}
                            {!data.timetables[index].global && !data.timetables[index].table.wednesday[0].closed && data.timetables[index].table.wednesday[0].afwijkend === false && (
                              <td className="row-container">
                                {data.timetables[index].table.wednesday.map((item, rowIndex) => (
                                  <span key={rowIndex} className={[data.timetables[index].table.wednesday.length > 1 ? "multiple" : "", "time-row"].join(" ")}>
                                    <div>
                                      <p>
                                        <input defaultValue={item.from} onBlur={(e) => timetableHandler(e.target.value, "wednesday", "from", index, rowIndex)} type="time" />
                                      </p>
                                      <p className="score">-</p>
                                      <p>
                                        <input defaultValue={item.until} onBlur={(e) => timetableHandler(e.target.value, "wednesday", "until", index, rowIndex)} type="time" />
                                      </p>

                                      {!data.timetables[index].table.wednesday[rowIndex].messageText && <img onClick={(e) => addSpecificMessage(e.target.value, "wednesday", index, rowIndex, "check")} alt="message toggle" width="30px" src="https://static.thenounproject.com/png/3144347-200.png" />}

                                      {data.timetables[index].table.wednesday[rowIndex].messageText && <img onClick={(e) => addSpecificMessage(e.target.value, "wednesday", index, rowIndex, "check")} alt="message toggle" width="30px" src="https://static.thenounproject.com/png/3144348-200.png" />}
                                      <Icon onClick={(e) => AddTime(e, index, "wednesday")} name="AddCircle" />
                                    </div>
                                    {data.timetables[index].table.wednesday[rowIndex].messageText && (
                                      <div className="message">
                                        <input defaultValue={data.timetables[index].table.wednesday[rowIndex].messageText} onBlur={(e) => addSpecificMessage(e.target.value, "wednesday", index, rowIndex, "message")} />
                                      </div>
                                    )}
                                  </span>
                                ))}
                              </td>
                            )}
                            {data.timetables[index].table.wednesday[0].closed && (
                              <td className="closed">
                                <span className="text">{translate('ci_closed')}</span>
                              </td>
                            )}
                            {!data.timetables[index].global && <td />}
                            {data.timetables[index].global && !data.timetables[index].table.wednesday[0].afwijkend && (
                              <td className="closed row-container">
                                {!data.timetables[index].table.wednesday[0].closed && data.timetables[index].table.wednesday.map((item, rowIndex) => (
                                  <span key={rowIndex} className={[data.timetables[index].table.wednesday.length > 1 ? "multiple" : "", "time-row"].join(" ")}>
                                    <div>
                                      <p>
                                        <input disabled defaultValue={item.from} type="time" />
                                      </p>
                                      <p className="score">-</p>
                                      <p>
                                        <input disabled defaultValue={item.until} type="time" />
                                      </p>
                                    </div>
                                  </span>
                                ))}
                              </td>
                            )}

                            <td>
                              {data.timetables[index].table.wednesday.length < 2 && (
                                <OsnCheckbox
                                  checked={data.timetables[index].table.wednesday[0].closed}
                                  name={String(`${index} table wednesday 0 closed`)}
                                  onChange={(e) => {
                                    changeClosed(e, index, "wednesday");
                                  }}
                                />
                              )}
                              {data.timetables[index].table.wednesday.length > 1 && (data.timetables[index].table.wednesday[0].afwijkend || !data.timetables[index].global) && (
                                <span className="row-container">
                                  {data.timetables[index].table.wednesday.map((item, rowIndex) => (
                                    <Icon name="Bin" onClick={(e) => removeTime(e, index, rowIndex, "wednesday")} key={rowIndex} />
                                  ))}
                                </span>
                              )}
                            </td>
                            {data.timetables[index].global && (
                              <td>
                                <OsnCheckbox
                                  checked={data.timetables[index].table.wednesday[0].afwijkend}
                                  name={String(`${index} table wednesday 0 afwijkend`)}
                                  onChange={(e) => {
                                    changeAfwijkend(e, index, "wednesday");
                                  }}
                                />
                              </td>
                            )}
                          </tr>
                          <tr>
                            <td>{translate('ci_thursday')}</td>
                            {!data.timetables[index].table.thursday[0].closed && data.timetables[index].table.thursday[0].afwijkend && (
                              <td className="row-container">
                                {data.timetables[index].table.thursday.map((item, rowIndex) => (
                                  <span key={rowIndex} className={[data.timetables[index].table.thursday.length > 1 ? "multiple" : "", "time-row"].join(" ")}>
                                    <div>
                                      <p>
                                        <input defaultValue={item.from} onBlur={(e) => timetableHandler(e.target.value, "thursday", "from", index, rowIndex)} type="time" />
                                      </p>
                                      <p className="score">-</p>
                                      <p>
                                        <input defaultValue={item.until} onBlur={(e) => timetableHandler(e.target.value, "thursday", "until", index, rowIndex)} type="time" />
                                      </p>
                                      {!data.timetables[index].table.thursday[rowIndex].messageText && <img onClick={(e) => addSpecificMessage(e.target.value, "thursday", index, rowIndex, "check")} alt="message toggle" width="30px" src="https://static.thenounproject.com/png/3144347-200.png" />}
                                      {data.timetables[index].table.thursday[rowIndex].messageText && <img onClick={(e) => addSpecificMessage(e.target.value, "thursday", index, rowIndex, "check")} alt="message toggle" width="30px" src="https://static.thenounproject.com/png/3144348-200.png" />}
                                      <Icon onClick={(e) => AddTime(e, index, "thursday")} name="AddCircle" />
                                    </div>
                                    {data.timetables[index].table.thursday[rowIndex].messageText && (
                                      <div className="message">
                                        <input defaultValue={data.timetables[index].table.thursday[rowIndex].messageText} onBlur={(e) => addSpecificMessage(e.target.value, "thursday", index, rowIndex, "message")} />
                                      </div>
                                    )}
                                  </span>
                                ))}
                              </td>
                            )}
                            {!data.timetables[index].global && !data.timetables[index].table.thursday[0].closed && data.timetables[index].table.thursday[0].afwijkend === false && (
                              <td className="row-container">
                                {data.timetables[index].table.thursday.map((item, rowIndex) => (
                                  <span key={rowIndex} className={[data.timetables[index].table.thursday.length > 1 ? "multiple" : "", "time-row"].join(" ")}>
                                    <div>
                                      <p>
                                        <input defaultValue={item.from} onBlur={(e) => timetableHandler(e.target.value, "thursday", "from", index, rowIndex)} type="time" />
                                      </p>
                                      <p className="score">-</p>
                                      <p>
                                        <input defaultValue={item.until} onBlur={(e) => timetableHandler(e.target.value, "thursday", "until", index, rowIndex)} type="time" />
                                      </p>
                                      {!data.timetables[index].table.thursday[rowIndex].messageText && <img onClick={(e) => addSpecificMessage(e.target.value, "thursday", index, rowIndex, "check")} alt="message toggle" width="30px" src="https://static.thenounproject.com/png/3144347-200.png" />}
                                      {data.timetables[index].table.thursday[rowIndex].messageText && <img onClick={(e) => addSpecificMessage(e.target.value, "thursday", index, rowIndex, "check")} alt="message toggle" width="30px" src="https://static.thenounproject.com/png/3144348-200.png" />}
                                      <Icon onClick={(e) => AddTime(e, index, "thursday")} name="AddCircle" />
                                    </div>
                                    {data.timetables[index].table.thursday[rowIndex].messageText && (
                                      <div className="message">
                                        <input defaultValue={data.timetables[index].table.thursday[rowIndex].messageText} onBlur={(e) => addSpecificMessage(e.target.value, "thursday", index, rowIndex, "message")} />
                                      </div>
                                    )}
                                  </span>
                                ))}
                              </td>
                            )}
                            {data.timetables[index].table.thursday[0].closed && (
                              <td className="closed">
                                <span className="text">{translate('ci_closed')}</span>
                              </td>
                            )}
                            {data.timetables[index].global && !data.timetables[index].table.thursday[0].afwijkend && (
                              <td className="closed row-container">
                                {!data.timetables[index].table.thursday[0].closed && data.timetables[index].table.thursday.map((item, rowIndex) => (
                                  <span key={rowIndex} className={[data.timetables[index].table.thursday.length > 1 ? "multiple" : "", "time-row"].join(" ")}>
                                    <div>
                                      <p>
                                        <input disabled defaultValue={item.from} type="time" />
                                      </p>
                                      <p className="score">-</p>
                                      <p>
                                        <input disabled defaultValue={item.until} type="time" />
                                      </p>
                                    </div>
                                  </span>
                                ))}
                              </td>
                            )}
                            {!data.timetables[index].global && <td />}

                            <td>
                              {data.timetables[index].table.thursday.length < 2 && (
                                <OsnCheckbox
                                  checked={data.timetables[index].table.thursday[0].closed}
                                  name={String(`${index} table thursday 0 closed`)}
                                  onChange={(e) => {
                                    changeClosed(e, index, "thursday");
                                  }}
                                />
                              )}
                              {data.timetables[index].table.thursday.length > 1 && (data.timetables[index].table.thursday[0].afwijkend || !data.timetables[index].global) && (
                                <span className="row-container">
                                  {data.timetables[index].table.thursday.map((item, rowIndex) => (
                                    <Icon name="Bin" onClick={(e) => removeTime(e, index, rowIndex, "thursday")} key={rowIndex} />
                                  ))}
                                </span>
                              )}
                            </td>

                            {data.timetables[index].global && (
                              <td>
                                <OsnCheckbox
                                  checked={data.timetables[index].table.thursday[0].afwijkend}
                                  name={String(`${index} table thursday 0 afwijkend`)}
                                  onChange={(e) => {
                                    changeAfwijkend(e, index, "thursday");
                                  }}
                                />
                              </td>
                            )}
                          </tr>
                          <tr>
                            <td>{translate('ci_friday')}</td>
                            {!data.timetables[index].table.friday[0].closed && data.timetables[index].table.friday[0].afwijkend && (
                              <td className="row-container">
                                {data.timetables[index].table.friday.map((item, rowIndex) => (
                                  <span key={rowIndex} className={[data.timetables[index].table.friday.length > 1 ? "multiple" : "", "time-row"].join(" ")}>
                                    <div>
                                      <p>
                                        <input defaultValue={item.from} onBlur={(e) => timetableHandler(e.target.value, "friday", "from", index, rowIndex)} type="time" />
                                      </p>
                                      <p className="score">-</p>
                                      <p>
                                        <input defaultValue={item.until} onBlur={(e) => timetableHandler(e.target.value, "friday", "until", index, rowIndex)} type="time" />
                                      </p>
                                      {!data.timetables[index].table.friday[rowIndex].messageText && <img onClick={(e) => addSpecificMessage(e.target.value, "friday", index, rowIndex, "check")} alt="message toggle" width="30px" src="https://static.thenounproject.com/png/3144347-200.png" />}

                                      {data.timetables[index].table.friday[rowIndex].messageText && <img onClick={(e) => addSpecificMessage(e.target.value, "friday", index, rowIndex, "check")} alt="message toggle" width="30px" src="https://static.thenounproject.com/png/3144348-200.png" />}
                                      <Icon onClick={(e) => AddTime(e, index, "friday")} name="AddCircle" />
                                    </div>
                                    {data.timetables[index].table.friday[rowIndex].messageText && (
                                      <div className="message">
                                        <input defaultValue={data.timetables[index].table.friday[rowIndex].messageText} onBlur={(e) => addSpecificMessage(e.target.value, "friday", index, rowIndex, "message")} />
                                      </div>
                                    )}
                                  </span>
                                ))}
                              </td>
                            )}
                            {!data.timetables[index].global && !data.timetables[index].table.friday[0].closed && data.timetables[index].table.friday[0].afwijkend === false && (
                              <td className="row-container">
                                {data.timetables[index].table.friday.map((item, rowIndex) => (
                                  <span key={rowIndex} className={[data.timetables[index].table.friday.length > 1 ? "multiple" : "", "time-row"].join(" ")}>
                                    <div>
                                      <p>
                                        <input defaultValue={item.from} onBlur={(e) => timetableHandler(e.target.value, "friday", "from", index, rowIndex)} type="time" />
                                      </p>
                                      <p className="score">-</p>
                                      <p>
                                        <input defaultValue={item.until} onBlur={(e) => timetableHandler(e.target.value, "friday", "until", index, rowIndex)} type="time" />
                                      </p>
                                      {!data.timetables[index].table.friday[rowIndex].messageText && <img onClick={(e) => addSpecificMessage(e.target.value, "friday", index, rowIndex, "check")} alt="message toggle" width="30px" src="https://static.thenounproject.com/png/3144347-200.png" />}
                                      {data.timetables[index].table.friday[rowIndex].messageText && <img onClick={(e) => addSpecificMessage(e.target.value, "friday", index, rowIndex, "check")} alt="message toggle" width="30px" src="https://static.thenounproject.com/png/3144348-200.png" />}
                                      <Icon onClick={(e) => AddTime(e, index, "friday")} name="AddCircle" />
                                    </div>
                                    {data.timetables[index].table.friday[rowIndex].messageText && (
                                      <div className="message">
                                        <input defaultValue={data.timetables[index].table.friday[rowIndex].messageText} onBlur={(e) => addSpecificMessage(e.target.value, "friday", index, rowIndex, "message")} />
                                      </div>
                                    )}
                                  </span>
                                ))}
                              </td>
                            )}
                            {data.timetables[index].table.friday[0].closed && (
                              <td className="closed">
                                <span className="text">{translate('ci_closed')}</span>
                              </td>
                            )}
                            {data.timetables[index].global && !data.timetables[index].table.friday[0].afwijkend && (
                              <td className="closed row-container">
                                {!data.timetables[index].table.friday[0].closed && data.timetables[index].table.friday.map((item, rowIndex) => (
                                  <span key={rowIndex} className={[data.timetables[index].table.friday.length > 1 ? "multiple" : "", "time-row"].join(" ")}>
                                    <div>
                                      <p>
                                        <input disabled defaultValue={item.from} type="time" />
                                      </p>
                                      <p className="score">-</p>
                                      <p>
                                        <input disabled defaultValue={item.until} type="time" />
                                      </p>
                                    </div>
                                  </span>
                                ))}
                              </td>
                            )}

                            {!data.timetables[index].global && <td />}

                            <td>
                              {data.timetables[index].table.friday.length < 2 && (
                                <OsnCheckbox
                                  checked={data.timetables[index].table.friday[0].closed}
                                  name={String(`${index} table friday 0 closed`)}
                                  onChange={(e) => {
                                    changeClosed(e, index, "friday");
                                  }}
                                />
                              )}
                              {data.timetables[index].table.friday.length > 1 && (data.timetables[index].table.friday[0].afwijkend || !data.timetables[index].global) && (
                                <span className="row-container">
                                  {data.timetables[index].table.friday.map((item, rowIndex) => (
                                    <Icon name="Bin" onClick={(e) => removeTime(e, index, rowIndex, "friday")} key={rowIndex} />
                                  ))}
                                </span>
                              )}
                            </td>
                            {data.timetables[index].global && (
                              <td>
                                <OsnCheckbox
                                  checked={data.timetables[index].table.friday[0].afwijkend}
                                  name={String(`${index} table friday 0 afwijkend`)}
                                  onChange={(e) => {
                                    changeAfwijkend(e, index, "friday");
                                  }}
                                />
                              </td>
                            )}
                          </tr>
                          <tr>
                            <td>{translate('ci_saturday')}</td>
                            {!data.timetables[index].table.saturday[0].closed && data.timetables[index].table.saturday[0].afwijkend && (
                              <td className="row-container">
                                {data.timetables[index].table.saturday.map((item, rowIndex) => (
                                  <span key={rowIndex} className={[data.timetables[index].table.saturday.length > 1 ? "multiple" : "", "time-row"].join(" ")}>
                                    <div>
                                      <p>
                                        <input defaultValue={item.from} onBlur={(e) => timetableHandler(e.target.value, "saturday", "from", index, rowIndex)} type="time" />
                                      </p>
                                      <p className="score">-</p>
                                      <p>
                                        <input defaultValue={item.until} onBlur={(e) => timetableHandler(e.target.value, "saturday", "until", index, rowIndex)} type="time" />
                                      </p>
                                      {!data.timetables[index].table.saturday[rowIndex].messageText && <img onClick={(e) => addSpecificMessage(e.target.value, "saturday", index, rowIndex, "check")} alt="message toggle" width="30px" src="https://static.thenounproject.com/png/3144347-200.png" />}

                                      {data.timetables[index].table.saturday[rowIndex].messageText && <img onClick={(e) => addSpecificMessage(e.target.value, "saturday", index, rowIndex, "check")} alt="message toggle" width="30px" src="https://static.thenounproject.com/png/3144348-200.png" />}
                                      <Icon onClick={(e) => AddTime(e, index, "saturday")} name="AddCircle" />
                                    </div>
                                    {data.timetables[index].table.saturday[rowIndex].messageText && (
                                      <div className="message">
                                        <input defaultValue={data.timetables[index].table.saturday[rowIndex].messageText} onBlur={(e) => addSpecificMessage(e.target.value, "saturday", index, rowIndex, "message")} />
                                      </div>
                                    )}
                                  </span>
                                ))}
                              </td>
                            )}
                            {!data.timetables[index].global && !data.timetables[index].table.saturday[0].closed && data.timetables[index].table.saturday[0].afwijkend === false && (
                              <td className="row-container">
                                {data.timetables[index].table.saturday.map((item, rowIndex) => (
                                  <span key={rowIndex} className={[data.timetables[index].table.saturday.length > 1 ? "multiple" : "", "time-row"].join(" ")}>
                                    <div>
                                      <p>
                                        <input defaultValue={item.from} onBlur={(e) => timetableHandler(e.target.value, "saturday", "from", index, rowIndex)} type="time" />
                                      </p>
                                      <p className="score">-</p>
                                      <p>
                                        <input defaultValue={item.until} onBlur={(e) => timetableHandler(e.target.value, "saturday", "until", index, rowIndex)} type="time" />
                                      </p>
                                      {!data.timetables[index].table.saturday[rowIndex].messageText && <img onClick={(e) => addSpecificMessage(e.target.value, "saturday", index, rowIndex, "check")} alt="message toggle" width="30px" src="https://static.thenounproject.com/png/3144347-200.png" />}
                                      {data.timetables[index].table.saturday[rowIndex].messageText && <img onClick={(e) => addSpecificMessage(e.target.value, "saturday", index, rowIndex, "check")} alt="message toggle" width="30px" src="https://static.thenounproject.com/png/3144348-200.png" />}
                                      <Icon onClick={(e) => AddTime(e, index, "saturday")} name="AddCircle" />
                                    </div>
                                    {data.timetables[index].table.saturday[rowIndex].messageText && (
                                      <div className="message">
                                        <input defaultValue={data.timetables[index].table.saturday[rowIndex].messageText} onBlur={(e) => addSpecificMessage(e.target.value, "saturday", index, rowIndex, "message")} />
                                      </div>
                                    )}
                                  </span>
                                ))}
                              </td>
                            )}
                            {data.timetables[index].table.saturday[0].closed && (
                              <td className="closed">
                                <span className="text">{translate('ci_closed')}</span>
                              </td>
                            )}
                            {data.timetables[index].global && !data.timetables[index].table.saturday[0].afwijkend && (
                              <td className="closed row-container">
                                {!data.timetables[index].table.saturday[0].closed && data.timetables[index].table.saturday.map((item, rowIndex) => (
                                  <span key={rowIndex} className={[data.timetables[index].table.saturday.length > 1 ? "multiple" : "", "time-row"].join(" ")}>
                                    <div>
                                      <p>
                                        <input disabled defaultValue={item.from} type="time" />
                                      </p>
                                      <p className="score">-</p>
                                      <p>
                                        <input disabled defaultValue={item.until} type="time" />
                                      </p>
                                    </div>
                                  </span>
                                ))}
                              </td>
                            )}
                            {!data.timetables[index].global && <td />}
                            <td>
                              {data.timetables[index].table.saturday.length < 2 && (
                                <OsnCheckbox
                                  checked={data.timetables[index].table.saturday[0].closed}
                                  name={String(`${index} table saturday 0 closed`)}
                                  onChange={(e) => {
                                    changeClosed(e, index, "saturday");
                                  }}
                                />
                              )}
                              {data.timetables[index].table.saturday.length > 1 && (data.timetables[index].table.saturday[0].afwijkend || !data.timetables[index].global) && (
                                <span className="row-container">
                                  {data.timetables[index].table.saturday.map((item, rowIndex) => (
                                    <Icon name="Bin" onClick={(e) => removeTime(e, index, rowIndex, "saturday")} key={rowIndex} />
                                  ))}
                                </span>
                              )}
                            </td>
                            {data.timetables[index].global && (
                              <td>
                                <OsnCheckbox
                                  checked={data.timetables[index].table.saturday[0].afwijkend}
                                  name={String(`${index} table saturday 0 afwijkend`)}
                                  onChange={(e) => {
                                    changeAfwijkend(e, index, "saturday");
                                  }}
                                />
                              </td>
                            )}
                          </tr>
                          <tr>
                            <td>{translate('ci_sunday')}</td>
                            {!data.timetables[index].table.sunday[0].closed && data.timetables[index].table.sunday[0].afwijkend && (
                              <td className="row-container">
                                {data.timetables[index].table.sunday.map((item, rowIndex) => (
                                  <span key={rowIndex} className={[data.timetables[index].table.sunday.length > 1 ? "multiple" : "", "time-row"].join(" ")}>
                                    <div>
                                      <p>
                                        <input defaultValue={item.from} onBlur={(e) => timetableHandler(e.target.value, "sunday", "from", index, rowIndex)} type="time" />
                                      </p>
                                      <p className="score">-</p>
                                      <p>
                                        <input defaultValue={item.until} onBlur={(e) => timetableHandler(e.target.value, "sunday", "until", index, rowIndex)} type="time" />
                                      </p>
                                      {!data.timetables[index].table.sunday[rowIndex].messageText && <img onClick={(e) => addSpecificMessage(e.target.value, "sunday", index, rowIndex, "check")} alt="message toggle" width="30px" src="https://static.thenounproject.com/png/3144347-200.png" />}

                                      {data.timetables[index].table.sunday[rowIndex].messageText && <img onClick={(e) => addSpecificMessage(e.target.value, "sunday", index, rowIndex, "check")} alt="message toggle" width="30px" src="https://static.thenounproject.com/png/3144348-200.png" />}
                                      <Icon onClick={(e) => AddTime(e, index, "sunday")} name="AddCircle" />
                                    </div>
                                    {data.timetables[index].table.sunday[rowIndex].messageText && (
                                      <div className="message">
                                        <input defaultValue={data.timetables[index].table.sunday[rowIndex].messageText} onBlur={(e) => addSpecificMessage(e.target.value, "sunday", index, rowIndex, "message")} />
                                      </div>
                                    )}
                                  </span>
                                ))}
                              </td>
                            )}
                            {!data.timetables[index].global && !data.timetables[index].table.sunday[0].closed && data.timetables[index].table.sunday[0].afwijkend === false && (
                              <td className="row-container">
                                {data.timetables[index].table.sunday.map((item, rowIndex) => (
                                  <span key={rowIndex} className={[data.timetables[index].table.sunday.length > 1 ? "multiple" : "", "time-row"].join(" ")}>
                                    <div>
                                      <p>
                                        <input defaultValue={item.from} onBlur={(e) => timetableHandler(e.target.value, "sunday", "from", index, rowIndex)} type="time" />
                                      </p>
                                      <p className="score">-</p>
                                      <p>
                                        <input defaultValue={item.until} onBlur={(e) => timetableHandler(e.target.value, "sunday", "until", index, rowIndex)} type="time" />
                                      </p>
                                      {!data.timetables[index].table.sunday[rowIndex].messageText && <img onClick={(e) => addSpecificMessage(e.target.value, "sunday", index, rowIndex, "check")} alt="message toggle" width="30px" src="https://static.thenounproject.com/png/3144347-200.png" />}
                                      {data.timetables[index].table.sunday[rowIndex].messageText && <img onClick={(e) => addSpecificMessage(e.target.value, "sunday", index, rowIndex, "check")} alt="message toggle" width="30px" src="https://static.thenounproject.com/png/3144348-200.png" />}
                                      <Icon onClick={(e) => AddTime(e, index, "sunday")} name="AddCircle" />
                                    </div>
                                    {data.timetables[index].table.sunday[rowIndex].messageText && (
                                      <div className="message">
                                        <input defaultValue={data.timetables[index].table.sunday[rowIndex].messageText} onBlur={(e) => addSpecificMessage(e.target.value, "sunday", index, rowIndex, "message")} />
                                      </div>
                                    )}
                                  </span>
                                ))}
                              </td>
                            )}
                            {data.timetables[index].table.sunday[0].closed && (
                              <td className="closed">
                                <span className="text">{translate('ci_closed')}</span>
                              </td>
                            )}
                            {data.timetables[index].global && !data.timetables[index].table.sunday[0].afwijkend && (
                              <td className="closed row-container">
                                {!data.timetables[index].table.sunday[0].closed && data.timetables[index].table.sunday.map((item, rowIndex) => (
                                  <span key={rowIndex} className={[data.timetables[index].table.sunday.length > 1 ? "multiple" : "", "time-row"].join(" ")}>
                                    <div>
                                      <p>
                                        <input disabled defaultValue={item.from} type="time" />
                                      </p>
                                      <p className="score">-</p>
                                      <p>
                                        <input disabled defaultValue={item.until} type="time" />
                                      </p>
                                    </div>
                                  </span>
                                ))}
                              </td>
                            )}
                            {!data.timetables[index].global && <td />}
                            <td>
                              {data.timetables[index].table.sunday.length < 2 && (
                                <OsnCheckbox
                                  checked={data.timetables[index].table.sunday[0].closed}
                                  name={String(`${index} table sunday 0 closed`)}
                                  onChange={(e) => {
                                    changeClosed(e, index, "sunday");
                                  }}
                                />
                              )}
                              {data.timetables[index].table.sunday.length > 1 && (data.timetables[index].table.sunday[0].afwijkend || !data.timetables[index].global) && (
                                <span className="row-container">
                                  {data.timetables[index].table.sunday.map((item, rowIndex) => (
                                    <Icon name="Bin" onClick={(e) => removeTime(e, index, rowIndex, "sunday")} key={rowIndex} />
                                  ))}
                                </span>
                              )}
                            </td>

                            {data.timetables[index].global && (
                              <td>
                                <OsnCheckbox
                                  checked={data.timetables[index].table.sunday[0].afwijkend}
                                  name={String(`${index} table sunday 0 afwijkend`)}
                                  onChange={(e) => {
                                    changeAfwijkend(e, index, "sunday");
                                  }}
                                />
                              </td>
                            )}
                          </tr>
                        </tbody>
                      }

                      <tfoot>
                        <tr>
                          <td>
                            <p className="yellow">{translate('want_set_period')} </p>
                            <p className="help">{translate('example_lowhigh_season')}</p>
                          </td>
                          <td>
                            <div onClick={(e) => addPeriode(e, index, "check")} className={["option3", data.timetables[index].periode ? "active" : ""].join(" ")}>
                              <p className="typo">{translate('ci_yes')}</p>
                              <p className="typo">{translate('ci_no')}</p>
                            </div>

                            {data.timetables[index].periode && (
                              <Fragment>
                                {data.timetables[index].periode_list.map((item, periodeIndex) => (
                                  <Fragment key={periodeIndex}>
                                    <div>
                                      <input defaultValue={item.periodeFrom} onBlur={(e) => addPeriode(e, index, "from", periodeIndex)} type="date" />
                                      <p className="score"> -</p>
                                      <input defaultValue={item.periodeUntil} onBlur={(e) => addPeriode(e, index, "until", periodeIndex)} type="date" />
                                      {!item.message && <img onClick={(e) => addPeriode(e, index, "toggleTrue", periodeIndex)} alt="message toggle" width="30px" src="https://static.thenounproject.com/png/3144347-200.png" />}
                                      {item.message && <img onClick={(e) => addPeriode(e, index, "toggleFalse", periodeIndex)} alt="message toggle" width="30px" src="https://static.thenounproject.com/png/3144348-200.png" />}
                                      <Icon name="AddCircle" onClick={(e) => addPeriode(e, index, "addPeriode", periodeIndex)} />
                                      {data.timetables[index].periode_list.length !== 1 && <Icon name="Bin" onClick={(e) => addPeriode(e, index, "removePeriode", periodeIndex)} />}
                                    </div>
                                    {item.message && <textarea placeholder="Extra info bij deze periode." defaultValue={item.periodeMessage} onBlur={(e) => addPeriode(e, index, "message", periodeIndex)} />}
                                  </Fragment>
                                ))}
                              </Fragment>
                            )}
                          </td>
                        </tr>
                        <tr><td><br /></td></tr>
                        <tr className="header">
                          <td style={{ "alignItems": "start" }}>
                            <p className="yellow">{translate('ci_exceptions')}</p>
                            <p className="help">{translate('ci_hollidays')}</p>
                          </td>
                        </tr>
                        {data.timetables[index].exceptions.length > 0 &&
                          <tr style={{ "minHeight": "32px" }}>
                            <th style={{ "minWidth": "125px" }}></th>
                            <th style={{ "textAlign": "left", "minWidth": "315px", "color": "var(--grey-90)", "fontSize": "14px", "fontWeight": "bold" }}>{translate('other_date')}</th>
                            <th style={{ "color": "var(--grey-90)", "fontSize": "14px" }}>{translate('ci_closed')}?</th>
                          </tr>
                        }
                        {data.timetables[index].exceptions.map((exception, idx) => (
                          <tr key={idx} style={{ "minHeight": "32px", "borderTop": "1px solid var(--main)" }}>
                            <td style={{ "minWidth": "145px", "marginRight": "10px" }}>
                              {exception.other ?
                                <input defaultValue={exception.date} onBlur={(e) => changeExceptionType(e, index, idx, "date")} type="date" />
                                :
                                <select onChange={(e) => changeExceptionType(e, index, idx, "type")}>
                                  {exception.holliday === "" && <option key={0}>{translate('select_1')}</option>}
                                  {exception.holliday === "ALL_HOLLIDAYS" && <option key={1} value={"ALL_HOLLIDAYS"}>{translate('all_holidays')}</option>}
                                  {exception.holliday === "NEW_YEAR" && <option key={2} value={"NEW_YEAR"}>{translate('new_year')}</option>}
                                  {exception.holliday === "EASTER" && <option key={3} value={"EASTER"}>{translate('pasen')}</option>}
                                  {exception.holliday === "EASTER_MONDAY" && <option key={4} value={"EASTER_MONDAY"}>{translate('easter_monday')}</option>}
                                  {exception.holliday === "LABOUR_DAY" && <option key={5} value={"LABOUR_DAY"}>{translate('labour_day')}</option>}
                                  {exception.holliday === "ASCENSION_DAY" && <option key={6} value={"ASCENSION_DAY"}>{translate('ascension_day')}</option>}
                                  {exception.holliday === "WHIT_MONDAY" && <option key={7} value={"WHIT_MONDAY"}>{translate('whit_monday')}</option>}
                                  {exception.holliday === "PENTECOST" && <option key={8} value={"PENTECOST"}>{translate('pentecost')}</option>}
                                  {exception.holliday === "NATIONAL_HOLLIDAY" && <option key={9} value={"NATIONAL_HOLLIDAY"}>{translate('nationial_holliday')}</option>}
                                  {exception.holliday === "ASSUMPTION_OF_MARY" && <option key={10} value={"ASSUMPTION_OF_MARY"}>{translate('assumption_of_mary')}</option>}
                                  {exception.holliday === "ALL_SAINTS_DAY" && <option key={11} value={"ALL_SAINTS_DAY"}>{translate('all_saints_day')}</option>}
                                  {exception.holliday === "ARMISCTICE_DAY" && <option key={12} value={"ARMISCTICE_DAY"}>{translate('armistice_day')}</option>}
                                  {exception.holliday === "CHRISTMAS" && <option key={13} value={"CHRISTMAS"}>{translate('christmas')}</option>}
                                  {exception.holliday !== "" && <option key={0}>{translate('select_1')}</option>}
                                  {exception.holliday !== "ALL_HOLLIDAYS" && <option key={1} value={"ALL_HOLLIDAYS"}>{translate('all_holidays')}</option>}
                                  {exception.holliday !== "NEW_YEAR" && <option key={2} value={"NEW_YEAR"}>{translate('new_year')}</option>}
                                  {exception.holliday !== "EASTER" && <option key={3} value={"EASTER"}>{translate('pasen')}</option>}
                                  {exception.holliday !== "EASTER_MONDAY" && <option key={4} value={"EASTER_MONDAY"}>{translate('easter_monday')}</option>}
                                  {exception.holliday !== "LABOUR_DAY" && <option key={5} value={"LABOUR_DAY"}>{translate('labour_day')}</option>}
                                  {exception.holliday !== "ASCENSION_DAY" && <option key={6} value={"ASCENSION_DAY"}>{translate('ascension_day')}</option>}
                                  {exception.holliday !== "WHIT_MONDAY" && <option key={7} value={"WHIT_MONDAY"}>{translate('whit_monday')}</option>}
                                  {exception.holliday !== "PENTECOST" && <option key={8} value={"PENTECOST"}>{translate('pentecost')}</option>}
                                  {exception.holliday !== "NATIONAL_HOLLIDAY" && <option key={9} value={"NATIONAL_HOLLIDAY"}>{translate('nationial_holliday')}</option>}
                                  {exception.holliday !== "ASSUMPTION_OF_MARY" && <option key={10} value={"ASSUMPTION_OF_MARY"}>{translate('assumption_of_mary')}</option>}
                                  {exception.holliday !== "ALL_SAINTS_DAY" && <option key={11} value={"ALL_SAINTS_DAY"}>{translate('all_saints_day')}</option>}
                                  {exception.holliday !== "ARMISCTICE_DAY" && <option key={12} value={"ARMISCTICE_DAY"}>{translate('armistice_day')}</option>}
                                  {exception.holliday !== "CHRISTMAS" && <option key={13} value={"CHRISTMAS"}>{translate('christmas')}</option>}
                                </select>
                              }
                            </td>
                            <td style={{ "minWidth": "30px" }}>
                              {/* Because for some reason (idx always 0) the Checkbox module doesn't work here, we copy the full code of it in here and handle it this way */}
                              <div onClick={(e) => changeExceptionType(e, index, idx, "other")} className={`checkBox ${exception.other ? 'active' : ''}`}>
                                <label htmlFor={`checkBox`}>
                                  <input type="checkbox" checked={exception.other} onChange={() => 'ok'} />
                                  <div className={`checkboxBox ${exception.other ? 'active' : ''}`}>
                                    <div className='checkboxBoxSelected' />
                                  </div>
                                  <span>{exception.other}</span>
                                </label>
                              </div>
                            </td>
                            <td style={{ "minWidth": "275px" }} className="row-container">
                              {!exception.closed ? exception.slots.map((item, rowIndex) => (
                                <span key={rowIndex} className={[exception.slots.length > 1 ? "multiple" : "", "time-row"].join(" ")}>
                                  <div>
                                    <p>
                                      <input disabled={exception.closed} defaultValue={item.from} onBlur={(e) => changeExceptionType(e, index, idx, "from", rowIndex)} type="time" />
                                    </p>
                                    <p className="score">-</p>
                                    <p>
                                      <input disabled={exception.closed} defaultValue={item.until} onBlur={(e) => changeExceptionType(e, index, idx, "until", rowIndex)} type="time" />
                                    </p>
                                    {!item.messageText && item.messageText != "" && <img onClick={(e) => changeExceptionType(e, index, idx, "messageAdd", rowIndex)} alt="message toggle" width="30px" src="https://static.thenounproject.com/png/3144347-200.png" />}
                                    {item.messageText || item.messageText == "" && <img onClick={(e) => changeExceptionType(e, index, idx, "messageRemove", rowIndex)} alt="message toggle" width="30px" src="https://static.thenounproject.com/png/3144348-200.png" />}
                                    <Icon onClick={(e) => changeExceptionType(e, index, idx, "addTime", rowIndex)} name="AddCircle" />
                                  </div>

                                  {item.messageText || item.messageText == "" && (
                                    <div className="message">
                                      <input defaultValue={item.messageText} onBlur={(e) => changeExceptionType(e, index, idx, "messageChange", rowIndex)} />
                                    </div>
                                  )}
                                </span>

                              )) : <span style={{ "width": "100%", "textAlign": "left" }} className="text">{translate('ci_closed')}</span>}
                            </td>
                            <td>
                              {exception.slots.length < 2 && (
                                <div onClick={(e) => changeExceptionType(e, index, idx, "closed")} className={`checkBox ${exception.closed ? 'active' : ''}`}>
                                  <label htmlFor={`checkBox`}>
                                    <input type="checkbox" checked={exception.closed} onChange={() => 'ok'} />
                                    <div className={`checkboxBox ${exception.closed ? 'active' : ''}`}>
                                      <div className='checkboxBoxSelected' />
                                    </div>
                                    <span>{exception.closed}</span>
                                  </label>
                                </div>
                              )}
                              {exception.slots.length > 1 && (
                                <span className="row-container">
                                  {exception.slots.map((item, rowIndex) => (
                                    <Icon name="Bin" onClick={(e) => changeExceptionType(e, index, idx, "removeTime", rowIndex)} key={rowIndex} />
                                  ))}
                                </span>
                              )}
                            </td>
                            <td style={{ "alignItems": "end", "marginRight": "15px" }}>
                              <Icon name="Bin" onClick={(e) => changeExceptionType(e, index, idx, "removeException")} />
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td style={{ "alignItems": "start" }}>
                            <Icon name="AddCircle" onClick={(e) => changeExceptionType(e, index, 0, "addException")} />
                          </td>
                        </tr>
                        <tr><td><br /></td></tr>
                        <tr>
                          <td>
                            <p className="yellow">{translate('ci_set_message')} </p>
                            <p className="help">{translate('extra_info_about_timetable')}</p>
                          </td>
                          <td>
                            <textarea defaultValue={data.timetables[index].message} placeholder={translate('enter_a_message')} onBlur={(e) => changeTimetableMessage(e, index, "timetable_message")} />
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                  <div className="c-timetables__bin">
                    <Icon name="Bin" onClick={(e) => deleteTable(e, index)} />
                  </div>
                </div>
              ))}
            </div>
          )}
          {!data.timetables && <p>Voeg het gewenste tijdschema toe.</p>}
        </Fragment>
      </div>
      <div className="button-container">
        <Button
          text={translate('previous')}
          type="sub"
          size="S"
          brderColor="main"
          onClick={(e) => {
            store.dispatch({ type: "countDown" });
            props.setTab(8);
          }}
        />
        <Button
          text={translate('next')}
          type="sub"
          size="S"
          brderColor="main"
          onClick={(e) => {
            if (!validateTimetableForm()) return;
            patchData(data, 10);
            store.dispatch({ type: "countUp" });
            props.setTab(10);
          }}
        />
      </div>
    </section>
  );
};

export default Tijdschema;
