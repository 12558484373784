import { useEffect, useState } from "react";
import axios from "axios";
import store from "../store";
import plenny from "../library/images/plenny-point.png";
import { Button, OsnInputText } from "@identitybuilding/idb-react-ui-elements";
import { updateCurrentEstablishment } from "../actions/GeneralAction";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

function useForceUpdate(){
  const [value, setValue] = useState(0); // integer state
  return () => setValue(value => value + 1); // update state to force render
  // An function that increment 👆🏻 the previous state like here 
  // is better than directly setting `value + 1`
}

const Personal = (props) => {
  const [data, setData] = useState(props.data);
  const [loaded, setLoaded] = useState(false);
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.general.lang);
  const is_admin = useSelector((state) => state.general.is_admin);
  const translate = useSelector((state) => state.general.translate);
  let { est, ent } = useParams();
  const forceUpdate = useForceUpdate();
  

  const patchData = (data, tab) => {
    // validate the email only if it provided, otherwise show a notification without using isProperEmail function and it should not go in the next page
    if (data.personal_email !== "" && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.personal_email)){
      props.createNotification("error", "Vul een geldig e-mailadres in");
      // set a conditionso that it should not go in the next page if the email is not valid
      return;
    }
    let copy = data
        if(tab > copy.available_tabs){copy.available_tabs = tab}
    dispatch(updateCurrentEstablishment(copy));
    setData(copy)
    
      if (localStorage.getItem("token")) {
        const headers = { "Content-Type": "application/json", Authorization: `Token ${localStorage.getItem("token")}` };
        axios.post(`${process.env.REACT_APP_BASE_MEDIA_URL}/ci/update/${est}/?lang=${lang}`, {
            headers: headers,
            data: copy,
            token: localStorage.getItem("token"),
            page: "personal"

          })
          .then(async (res) => {
            setLoaded(false);
            setLoaded(true);
          });
      }
      store.dispatch({ type: "countUp" });
props.setTab(2);

  };

  const changePrive = (e, type) => {
    e.preventDefault();
    let copy = data;

    if (type === "first_name") {
      copy.first_name = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
    }
    if (type === "last_name") {
      copy.last_name = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
    }
    if (type === "personal_mobile_phone") {
      let value = e.target.value.replace("+32", "0").replace("+32 (0)", "0").replace("+32", "0").replace("0032", "0").replace("/", "").replace("/", "").replace("(0)", "0").replace(".", "").replace("-", "").replaceAll(" ", "");
      if (value != "") {
        value = `${value.substring(0,4)} ${value.substring(4,7)} ${value.substring(7,10)}`
  }
      copy.personal_mobile_phone = value
    }
    if (type === "personal_fixed_phone") {
      let value = e.target.value.replace("+32", "0").replace("+32 (0)", "0").replace("+32", "0").replace("0032", "0").replace("/", "").replace("/", "").replace("(0)", "0").replace(".", "").replace("-", "").replaceAll(" ", "");
      let two_digits_prefix = ["02", "03", "04", "09"]
      if (value[0] != '+' && value != ''){
        if(two_digits_prefix.includes(value.substring(0,2))) {
          value = `${value.substring(0,2)} ${value.substring(2,5)} ${value.substring(5,7)} ${value.substring(7,9)}`
        }
        else {
          value = `${value.substring(0,3)} ${value.substring(3,5)} ${value.substring(5,7)} ${value.substring(7,9)}`
        }
      }
      copy.personal_fixed_phone = value
    }
    if (type === "personal_email") {
      copy.personal_email = e.target.value;
    }

    setData(copy);
    setLoaded(false);
    forceUpdate();
    setLoaded(true);

  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    setData(props.data);
    setLoaded(true);
  }, []);

  return (
    loaded && <section className="c-personal">
      <div className="intro">
        <h2>
          <span className="typo">02</span>
        </h2>
        <div>
          <h2>{translate('start_personal_info')}</h2>
          <p>
            {translate('the_information_below')} <b className="yellow">{translate('never')}</b> {translate('the_information_below_part2')} <br /> {translate('the_information_below_part3')}
          </p>
        </div>
      </div>

      <section className="form-container">
        <form onSubmit={(e) => changePrive(e)}>
          <div className="prive-inputs">
            <div>
              <OsnInputText title={translate('ci_prename')} icon="UserM" required defaultValue={data.first_name} className="input" onBlur={(e) => changePrive(e, "first_name")} />
            </div>
            <div>
              <OsnInputText title={translate('ci_name')} icon="UserM" required defaultValue={data.last_name} className="input" onBlur={(e) => changePrive(e, "last_name")} />
            </div>

            <div>
              <OsnInputText title={translate('ci_mobile')} icon="Cellphone" required defaultValue={data.personal_mobile_phone} className="input" onBlur={(e) => changePrive(e, "personal_mobile_phone")} />
            </div>

            <div>
              <OsnInputText title={translate('ci_phone')} icon="Phone" required defaultValue={data.personal_fixed_phone} className="input" type="tel" onBlur={(e) => changePrive(e, "personal_fixed_phone")} />
            </div>
          </div>
          <div>
            <OsnInputText title={translate('ci_mail')} icon="Mail" required defaultValue={data.personal_email} className="input wide" onBlur={(e) => changePrive(e, "personal_email")} />
          </div>
        </form>
        <img alt="plenny" src={plenny} />
      </section>
      <div className="button-container">
        <Button
          text={translate('previous')}
          type="sub"
          size="S"
          brderColor="main"
          onClick={(e) => {
            store.dispatch({ type: "countDown" });
            props.setTab(0);
          }}
        />
        <Button
          text={translate('next')}
          type="sub"
          size="S"
          brderColor="main"
          onClick={(e) => {
            if(data.personal_email !== "" || is_admin) {
              patchData(data, 2);
            }
            else {
              props.createNotification("warning", "Gelieve een email in te vullen.")
            }

          }}
        />
      </div>
    </section>
  );
};

export default Personal;
