import axios from "axios";
import { useSelector } from "react-redux";
import "./App.scss";
import Icon from "@identitybuilding/idb-react-iconlib";
import Navigation from "./components/Navigation";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useHistory, useParams } from "react-router-dom";

const Main = () => {
  const lang = useSelector((state) => state.general.lang);
  const translate = useSelector((state) => state.general.translate);
  const [searchValue, setSearchValue] = useState("");
  const [loading, setloading] = useState("");
  const [data, setData] = useState([]);
  let { est, ent } = useParams();

  const searchHandler = async (e, type) => {
    if (searchValue == "" && (e.keyCode === 13 || type === "button")) {
      setData([]);
    }
    if ((searchValue !== "" || e !== "") && (e.keyCode === 13 || type === "button")) {
      console.log(searchValue);
      let value = searchValue || "";
      value = searchValue?.replaceAll(".", "").replace("BE", "");
      if (value?.substring(0, 2) !== "0" && value?.length === 9) {
        value = `0${value}`;
      }
      // if (value.substring(0, 2) === 'BE') {
      //   value = `${value.replaceAll('.', '')}`
      // }
      // else {
      value = `BE${value?.replaceAll(".", "")}`;
      // }
      setloading(true);

      // this is for pagination
      let from = 0;
      let query = {
        bool: {
          minimum_should_match: 1,
          should: [
            {
              simple_query_string: {
                query: value,
                fields: ["number", "from_enterprise.number"],
                boost: 1100,
              },
            },
            // {
            //   "match_phrase_prefix": {
            //     "number": {
            //       "query": value,
            //       "boost": 900
            //     }
            //   }
            // },
            // {
            //   "match_phrase_prefix": {
            //     "from_enterprise.number": {
            //       "query": value,
            //       "boost": 900
            //     }
            //   }
            // },
            // {
            //   "fuzzy": {
            //     "from_enterprise": {
            //       "value": value,
            //       "boost": 80,
            //       "fuzziness": 1
            //     }
            //   }
            // },
            // {
            //   "fuzzy": {
            //     "number": {
            //       "value": value,
            //       "boost": 80,
            //       "fuzziness": 1
            //     }
            //   }
            // },
          ],
        },
      };

      let option = {};
      if (value === "") {
        option = {
          from: from,
          size: 100,
          sort: [
            {
              has_a_subscription: {
                order: "desc",
              },
            },
            "_score",
          ],
        };
      } else {
        option = {
          from: from,
          size: 100,
          query: query,
          sort: [
            {
              has_a_subscription: {
                order: "desc",
              },
            },
            "_score",
          ],
        };
      }

      await axios
        .get(
          `https://search-osn-management-hkfarflgp5aj2vbhfzvmyycuuy.eu-central-1.es.amazonaws.com/establishments/_search?`,
          {
            auth: {
              username: `${process.env.REACT_APP_ESUSERNAME}`,
              password: `${process.env.REACT_APP_ESPSW}`,
            },
            params: {
              source_content_type: "application/json",
              source: JSON.stringify(option),
            },
          }
        )
        .then((res) => {
          setData(res.data.hits.hits);
          setloading(false);
        });
    }
  };

  useEffect(() => {
    setSearchValue(ent);
    if (ent) {
      searchHandler(ent.toLocaleUpperCase(), "button");
    }
  }, []);

  function getLogoSrc(logolinks, lang) {
    const placeholder = require("./library/logos/logos/logo_placeholder.png");
    if (!logolinks || logolinks.length === 0) {
      return placeholder;
    }
  
    const fallbackLanguages = ['png_nl', 'png_fr', 'png_de', 'png_en'];
  
    // Helper function to get the logo for a specific index
    function getLogoAt(index) {
      if (logolinks[index]) {
        // Try to get the logo in the desired language
        const preferredLogo = logolinks[index][`png_${lang}`];
        if (preferredLogo) {
          return preferredLogo;
        }
  
        // If no preferred language logo is found, return any available logo
        const fallbackLogo = fallbackLanguages.find(langKey => logolinks[index][langKey]);
        if (fallbackLogo) {
          return logolinks[index][fallbackLogo];
        }
      }
      return null;
    }
  
    // Check the logos in the preferred order
    return getLogoAt(0) || getLogoAt(1) || getLogoAt(2) || placeholder;
  }
  

  // function getLogoSrc(logolinks, lang) {
  //   // Fallback to placeholder image if no logos are available
  //   const placeholder = require("./library/logos/logos/logo_placeholder.png");
  
  //   // Check if the logolinks array exists and has elements
  //   if (!logolinks || logolinks.length === 0) {
  //     return placeholder;
  //   }
  
  //   // Define the fallback languages
  //   const fallbackLanguages = ['png_nl', 'png_fr', 'png_de', 'png_en'];
  
  //   // Helper function to get the logo for a specific index
  //   function getLogoAt(index) {
  //     if (logolinks[index]) {
  //       return (
  //         logolinks[index][`png_${lang}`] ||
  //         fallbackLanguages.find(langKey => logolinks[index][langKey])
  //       );
  //     }
  //     return null;
  //   }
  
  //   // Check the logos in the preferred order
  //   return getLogoAt(0) || getLogoAt(1) || getLogoAt(2) || placeholder;
  // }
  

  return (
    // <section className="main_environment">
    //        {/* <Link to={`BE0718600051/BE2285183012/login`}>login</Link>
    //   <br />
    //   <Link to={`BE0718600051/BE2285183012/`}>brief</Link> */}
    //   <h2>{translate('welcome')} {ent}</h2>
    //   <p>{translate('search_and_select')}</p>
    //   <OsnInputText onChange={(e) => FilterResults(e.target.value)} placeholder={translate('search')} />
    //   <div className="cardWrapper">
    //     {loaded === true
    //       ? filteredEstablishments.map((data, index) => (
    //           <div className={`card`} key={index}>
    //             <figure>
    //               {data.logos.length > 0 ?
    //               <img src={data.logos[0][`png_${lang}`] || data.logos[0].png_nl || data.logos[0].png_fr || data.logos[0].png_de || data.logos[0].png_en} />
    //               :
    //               <img src={require("./library/images/download.png")} alt="plenny login"/>
    //               }
    //               </figure>
    //             <div className="cardContent">
    //               <div className="res_name">
    //                 <h2>{data.name}</h2>
    //                 <h2>({data.id})</h2>
    //               </div>

    //               <div className="cardRow">
    //                 <div style={{ width: "100%" }}>
    //                   <span className="card_address">
    //                     {data.address.streetname ?
    //                     `${data.address.streetname} ${data.address.house_number} ${data.address.box_number ? ` ${data.address.box_number}`: ""}`
    //                     :
    //                     "Uitgeschakeld door ondernemer"
    //                     }
    //                   </span>
    //                   <span className="card_address">
    //                     {data.address.streetname &&
    //                     `${data.address.postal_code} ${data.address.sub_municipality ? data.address.sub_municipality : data.address.municipality} ${data.address.municipality ? data.address.sub_municipality !== data.address.municipality ? `(${data.address.municipality})` : "" : ""}`
    //                     }
    //                     </span>
    //                 </div>
    //               </div>
    //             </div>
    //             <div className="cardLinks">
    //               <Link to={`/${ent}/${data.id}/login`}>
    //                 <Icon name="ArrowRight" />
    //               </Link>
    //             </div>
    //           </div>
    //         ))
    //       : "Loading"}
    //   </div>
    // </section>
    <section className="main_environment">
      <Navigation />
      <div className="head-image">
        <img src={require("./library/images/Mockup-OSN.jpg")} />
      </div>
      {/* <h2>Welkom op wijzig.100procentlokaal.be</h2>
      <h3>Klaar om jouw onderneming correct online te plaatsen?</h3> */}

{lang === "nl" ? (
    <h3>Leuk dat je hier bent . . . </h3>
) : lang === "fr" ? (
    <h3>C'est bien que tu sois là . . . </h3>
) : lang === "de" ? (
    <h3>Schön, dass du hier bist . . . </h3>
) : (
    <h3>Nice to have you here...</h3>
)}

{lang === "nl" ? (
    <p>
        Via deze pagina kan je je gegevens aanvullen of wijzigen op <br />
        100% Lokaal & het Ondernemersnetwerk.
    </p>
) : lang === "fr" ? (
    <p>
        Vous pouvez à tout moment avoir accès à, modifier ou compléter vos données personnelles sur <br />
        100% Local & le Réseau des Entrepreneurs.
    </p>
) : lang === "de" ? (
    <p>
        Über diese Seite können Sie Ihre Daten auf <br />
        100% Lokal & dem Unternehmernetzwerk hinzufügen oder ändern.
    </p>
) : (
    <p>Through this page you can add or change your data on <br />
    100% Local & the Entrepreneursnetwork.</p>
)}

{lang === "nl" ? (
    <p>
        Onze mascotte "Plenny" zal je helpen om een 12-delig stappenplan te volbrengen
        <br />
        zodat al jouw informatie direct en correct zichtbaar is.
    </p>
) : lang === "fr" ? (
    <p>
        Notre mascotte "Plenny" vous aidera à compléter un plan en 12 étapes de sorte
        <br />
        que toutes vos informations sont immédiatement et correctement visibles.
    </p>
) : lang === "de" ? (
    <p>
        Unser Maskottchen "Plenny" wird Ihnen helfen, einen 12-Schritte-Plan abzuschließen,
        <br />
        damit alle Ihre Informationen sofort und korrekt sichtbar sind.
    </p>
) : (
    <p>Our mascot “Plenny” will help you to complete a 12 steps plan
    so that all your information is immediately and correctly visible.</p>
)}

{lang === "nl" ? <h3>Veel succes!</h3> : lang === "fr" ? <h3>Bonne chance !</h3> : lang === "de" ? <h3>Viel Erfolg!</h3> : <h3>Good Luck!</h3>}


      <div className="search-engine">
    <div className="input-wrapper">
      {
        lang === "nl" ? (
          <h4>Zoek jouw onderneming a.d.h.v jouw ondernemingsnummer</h4>
        ) : lang === "fr" ? (
          <h4>Cherchez votre entreprise en utilisant votre numéro d'entreprise.</h4>
        ) : lang === "de" ? (
          <h4>Finden Sie Ihr Unternehmen anhand Ihrer Unternehmensnummer</h4>
        ) : (
          <h4>Find your company using your company number</h4>
        )
      } 


          <div className="input">
            <input
              placeholder={translate("search")}
              onKeyDown={(e) => searchHandler(e)}
              onChange={(e) => setSearchValue(e.target.value.toLocaleUpperCase())}
            />
            <span onClick={(e) => searchHandler(e, "button")}>
              <Icon name="ArrowRight" />
              <Icon name="Search" />
            </span>
          </div>
        </div>
        {!loading ? (
          <div className="cards">
            {data.length > 0 &&
              data.map((res, index) => (
                <div key={index} className="card">
                  <figure>
                    {
                      <img
                      src={getLogoSrc(res._source.logolinks, lang)}
                      alt="logo"
                    />
                    /* <img
                      src={
                        res._source.logolinks.length > 0
                          ? res._source.logolinks[0][`png_${lang}`] ||
                            res._source.logolinks[0].png_nl ||
                            res._source.logolinks[0].png_fr ||
                            res._source.logolinks[0].png_de ||
                            res._source.logolinks[0].png_en
                          : require("./library/logos/logos/logo_placeholder.png")
                      }
                      alt="logo establishment"
                    /> */}
                  </figure>
                  <div className="names">
                    <h2
                      title={`${
                        res._source[`name_${lang}`] ||
                        res._source.name_nl ||
                        res._source.name_fr ||
                        res._source.name_de ||
                        res._source.name_en
                      }`}
                    >
                      {res._source[`name_${lang}`] ||
                        res._source.name_nl ||
                        res._source.name_fr ||
                        res._source.name_de ||
                        res._source.name_en}
                    </h2>
                    <h3
                      title={`${
                        res._source.from_enterprise[`official_name_${lang}`] ||
                        res._source.from_enterprise.official_name_nl ||
                        res._source.from_enterprise.official_name_fr ||
                        res._source.from_enterprise.official_name_de ||
                        res._source.from_enterprise.official_name_en
                      } ${
                        res._source.from_enterprise[`official_name_${lang}`] ||
                        res._source.from_enterprise.juridical_form_nl ||
                        res._source.from_enterprise.juridical_form_fr ||
                        res._source.from_enterprise.juridical_form_de ||
                        res._source.from_enterprise.juridical_form_en
                      }`}
                    >
                      {res._source.from_enterprise.official_name_nl ||
                        res._source.from_enterprise.official_name_fr ||
                        res._source.from_enterprise.official_name_de ||
                        res._source.from_enterprise.official_name_en}{" "}
                      {res._source.from_enterprise.juridical_form_nl ||
                        res._source.from_enterprise.juridical_form_fr ||
                        res._source.from_enterprise.juridical_form_de ||
                        res._source.from_enterprise.juridical_form_en}
                    </h3>
                  </div>
                  <div className="cardRow">
                    <img
                      style={{ padding: "0" }}
                      className="icon"
                      src={require("./library/logos/nismarker.svg").default}
                      alt="marker icon"
                    />
                    <span
                      title={`${
                        res._source.address[`streetname_${lang}`] ||
                        res._source.address.streetname_nl ||
                        res._source.address.streetname_fr ||
                        res._source.address.streetname_de ||
                        res._source.address.streetname_en
                      } ${res._source.address.house_number} ${res._source.address.box_number} |  ${
                        res._source.address.postal_code
                      }  ${
                        res._source.address[`sub_municipality_${lang}`] ||
                        res._source.address.sub_municipality_nl ||
                        res._source.address.sub_municipality_fr ||
                        res._source.address.sub_municipality_de ||
                        res._source.address.sub_municipality_en
                      } (${
                        res._source.address[`municipality_${lang}`] ||
                        res._source.address.municipality_nl ||
                        res._source.address.municipality_fr ||
                        res._source.address.municipality_de ||
                        res._source.address.municipality_en
                      })`}
                    >
                      {res._source.address[`streetname_${lang}`] ||
                        res._source.address.streetname_nl ||
                        res._source.address.streetname_fr ||
                        res._source.address.streetname_de ||
                        res._source.address.streetname_en}{" "}
                      {res._source.address.house_number} {res._source.address.box_number} <br />
                      {res._source.address.postal_code}{" "}
                      {res._source.address[`sub_municipality_${lang}`] ||
                        res._source.address.sub_municipality_nl ||
                        res._source.address.sub_municipality_fr ||
                        res._source.address.sub_municipality_de ||
                        res._source.address.sub_municipality_en}{" "}
                      (
                      {res._source.address[`municipality_${lang}`] ||
                        res._source.address.municipality_nl ||
                        res._source.address.municipality_fr ||
                        res._source.address.municipality_de ||
                        res._source.address.municipality_en}
                      )
                    </span>
                  </div>
                  <div className="cardRow">
                    <Icon name="Info" />
                    <span>{res._source.number}</span>
                  </div>
                  <a
                    href={`../${res._source.from_enterprise.number}/${res._source.number}/login?lang=${lang}`}
                    className="next"
                  >
                    {/* Volgende */}
                    {/* Vul vestigin aan */}
                    {/* {lang === "nl" ? "Optimaliseer vestiging" : "Optimisez établissement"}  */}
                    {lang === 'nl' ? 'Optimaliseer vestiging'
                    : lang === 'fr' ? 'Optimisez établissement'
                    : lang === 'de' ? 'Optimieren Sie Einrichtung'
                    : 'Optimize establishment'
                    }

                    <Icon name="ArrowRight" />
                  </a>
                </div>
              ))}
          </div>
        ) : (
          loading === true && (
            <div className="cards" style={{ justifyContent: "center" }}>
              <Icon name="Loading" animated />
            </div>
          )
        )}
      </div>
    </section>
  );
};

export default Main;
